import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import "../css/cityguide-main-page.css";

const PaginationBlock = ({ total_pages, active_page, city_id, sub_category_id, inner_category_id, fetchPages}) => {
    const show_pages = 5; // Number of pages to show before and after the active page

    let items = [];
    const onPageChange = (page_number) => {
        fetchPages(city_id, sub_category_id, inner_category_id, page_number);
    };
    // Helper function to add pages to items array
    const addPages = (start, end) => {
        for (let inc = start; inc <= end; inc++) {
            if (active_page === inc) {
                items.push(<Pagination.Item key={inc} active>{inc}</Pagination.Item>);
            } else {
                items.push(
                    <Pagination.Item key={inc} onClick={() => fetchPages(city_id, sub_category_id, inner_category_id, inc)}>
                        {inc}
                    </Pagination.Item>
                );
            }
        }
    };

    // First Page button
    const handleFirstPage = () => {
        onPageChange(1);
    };

    // Previous Page button
    const handlePrevPage = () => {
        onPageChange(Math.max(active_page - 1, 1));
    };

    // Next Page button
    const handleNextPage = () => {
        onPageChange(Math.min(active_page + 1, total_pages));
    };

    // Last Page button
    const handleLastPage = () => {
        onPageChange(total_pages);
    };

    // Case: Less than or equal to 10 pages
    if (total_pages <= 10) {
        addPages(1, total_pages);
    } else {
        // Case: More than 10 pages
        if (active_page <= show_pages - 2) {
            // Show pages 1 to show_pages
            addPages(1, show_pages);
            items.push(<Pagination.Ellipsis key="ellipsis-start" />);
        } else {
            // Show first page
            items.push(
                <Pagination.Item key={1} onClick={handleFirstPage}>
                    {1}
                </Pagination.Item>
            );
            items.push(<Pagination.Ellipsis key="ellipsis-start" />);

            // Show pages around active_page
            const start = active_page - Math.floor(show_pages / 2);
            const end = active_page + Math.floor(show_pages / 2);

            if (end > total_pages) {
                const diff = end - total_pages;
                items.push(
                    <Pagination.Item key={start - 1} onClick={() => onPageChange(start - 1)}>
                        {start - 1}
                    </Pagination.Item>
                );
                addPages(start, total_pages);
            } else {
                addPages(start, end);
            }

            // Show last page
            items.push(<Pagination.Ellipsis key="ellipsis-end" />);
            items.push(
                <Pagination.Item key={total_pages} onClick={handleLastPage}>
                    {total_pages}
                </Pagination.Item>
            );
        }
    }

    return (      
        <>
            <div className='desktop-view-stack'>
                <div style={{display: "flex", justifyContent: "center", marginTop: "20px"}} >
                    <Pagination size='lg'>
                        <Pagination.First onClick={handleFirstPage} />
                        <Pagination.Prev onClick={handlePrevPage} />
                        {items}
                        <Pagination.Next onClick={handleNextPage} />
                        <Pagination.Last onClick={handleLastPage} />
                    </Pagination>
                </div>
            </div>
            <div className='mobile-view-stack'>
                <div style={{display: "flex", justifyContent: "center", marginTop: "20px"}} >
                    <Pagination size='sm'>
                        <Pagination.First onClick={handleFirstPage} />
                        <Pagination.Prev onClick={handlePrevPage} />
                        {items}
                        <Pagination.Next onClick={handleNextPage} />
                        <Pagination.Last onClick={handleLastPage} />
                    </Pagination>
                </div>
            </div>
            
        </>
        
    );
};

export default PaginationBlock;
