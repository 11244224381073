import "../css/Webstories.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faMicrochip } from "@fortawesome/free-solid-svg-icons";
import Image from 'react-bootstrap/Image';
import food_icon from "../images/food_icon.png"; 
import Badge from 'react-bootstrap/Badge';
import Carousel from "react-multi-carousel";
function Food(foodlist)
{
    const data_list = JSON.parse(foodlist.foodlist);
    const onclickOpenFood = () => {
        window.location = "/food";
    }
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 3
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 3
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
    return(
        <>
            {data_list!="" && 
                <Container  style={{marginBottom: "60px"}}>
                    <Row style={{marginBottom: "10px"}} >
                        <Col xxl={10} xl={10} lg={10} md={10} sm={12} xs={12}>
                        <h4 className="block-header"><Image src={food_icon} loading="lazy" /> Food</h4>
                        </Col>
                        <Col xxl={2} xl={2} lg={2} md={2} sm={12} xs={12} className="desktop-view-btn" style={{padding: "0px", textAlign: 'end'}}>
                            <Badge bg="default" className="more-text " style={{fontSize: "18px", background: "white !important", color: "#00a8a9", borderRadius: "20px", padding: "10px", cursor: "pointer", paddingLeft: "25px"}} onClick={onclickOpenFood}><span style={{color: "#00a8a9", fontWeight: 200}}>Explore More <FontAwesomeIcon icon={faChevronRight} style={{fontSize: "15px", paddingLeft: "10px"}} /></span></Badge>
                        </Col>
                    </Row>
                <Row style={{marginTop: "45px"}}>
                
                    {data_list.map((item, index) =>  
                        <Col xxxl={3} xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} xxs={12} className="desktop-view-btn">
                            <Card className="bg-dark text-white h-100">
                                <Card.Img src={"https://broadwayoriginals.com/"+item.img} alt="image" style={{height: "100%"}} loading="lazy" />
                                <Card.ImgOverlay>
                                    <Card.Title ><Nav.Link as={NavLink} to={"/food/"+item.url} ><div className="webstory_title" style={{bottom: "10%"}}>{item.name}</div></Nav.Link></Card.Title>
                                </Card.ImgOverlay>
                            </Card>
                        </Col>
                    )}
                    <Carousel responsive={responsive}>
                        {data_list.map((item, index) =>  
                            <Col xxxl={3} xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} xxs={12} className="mb-3 mobile-view-btn" >
                                <Card className="bg-dark text-white h-100" style={{marginRight: "15px"}}>
                                    <Card.Img src={"https://broadwayoriginals.com/"+item.img} alt="image" style={{height: "100%"}} loading="lazy" />
                                    <Card.ImgOverlay>
                                        <Card.Title ><Nav.Link as={NavLink} to={"/food/"+item.url} ><div className="webstory_title" style={{bottom: "10%"}}>{item.name}</div></Nav.Link></Card.Title>
                                    </Card.ImgOverlay>
                                </Card>
                            </Col>
                        )}
                    </Carousel> 
                </Row>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{padding: "0px", textAlign: 'end'}} className="mobile-view-btn mb-3">
                        <Badge bg="default" className="more-text mt-4" style={{fontSize: "18px", background: "none", color: "#00a8a9", borderRadius: "20px", padding: "10px", cursor: "pointer", paddingLeft: "25px", width: "100%", border: "1px solid #00a8a9"}} onClick={onclickOpenFood}><span style={{color: "#00a8a9", fontWeight: 200}}><span style={{float: "left"}}>Explore More</span> <FontAwesomeIcon icon={faChevronRight} style={{float: "right"}} /></span></Badge>
                        
                    </Col>
                </Row>
                </Container>
            }
        </>
        
        
    );
}

export default Food;