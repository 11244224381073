import React, { useState } from 'react';
import Stories from 'react-insta-stories';
import "../css/Webstories.css";
import webstoryicon from "../images/site-images/web-stories.png"; 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faTimes } from "@fortawesome/free-solid-svg-icons"; 
import { NavLink } from 'react-router-dom'; 
import Nav from 'react-bootstrap/Nav';
import Carousel from "react-multi-carousel";
import Badge from 'react-bootstrap/Badge';
function Webstories(data) {
    const data_list = JSON.parse(data.data);
    const [showOverlay, setShowOverlay] = useState(false);
    const [selectedStory, setSelectedStory] = useState(null);
    const [storyTitle, setStoryTitle] = useState("");

    const handleCloseOverlay = () => {
        setSelectedStory(null); 
        setStoryTitle(""); 
        setShowOverlay(false);
    };

    const handleShowOverlay = (story, title) => {
        setSelectedStory(story);
        setStoryTitle(title);
        setShowOverlay(true);
    };
    const onclickOpenTodayNews = () => {
        window.location = "/webstories";
    }
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
    return (
        <>
            {data_list.length > 0 &&
                <Container className="mt-5" style={{ marginBottom: "60px" }}>
                    <Row style={{ marginBottom: "10px", marginTop: "35px" }} >
                        <Col xxl={10} xl={10} lg={10} md={10} sm={12} xs={12}>
                            <h4 className="block-header"><Image src={webstoryicon} alt="Web Stories Icon" /> Web Stories</h4>
                        </Col>
                        <Col xxl={2} xl={2} lg={2} md={2} sm={12} xs={12} style={{ textAlign: "end" }} className="desktop-view-btn">
                            <Nav.Link as={NavLink} to={"/webstories"} className="right-float"><span className="more-text" >Explore More <FontAwesomeIcon icon={faChevronRight} /></span></Nav.Link>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "45px" }} >
                        {data_list.map((item, index) => (
                            <Col key={index} xxxl={3} xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} xxs={12} className="desktop-view-btn">
                                <div className="story-item" onClick={() => handleShowOverlay(item.files, item.title)}>
                                    <Image src={"https://broadwayoriginals.com/"+item.image} fluid />
                                    <div className="story-title">{item.title}</div>
                                </div>
                            </Col>
                        ))}
                        <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12} className="mobile-view-btn">
                            <Carousel responsive={responsive}>
                            {data_list.map((item, index) => (
                                <div className="story-item" onClick={() => handleShowOverlay(item.files, item.title)}>
                                    <Image src={"https://broadwayoriginals.com/"+item.image} fluid />
                                    <div className="story-title">{item.title}</div>
                                </div>           
                            ))}
                            </Carousel>  
                        </Col>
                    </Row>
                    <Row>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{padding: "0px", textAlign: 'end'}} className="mobile-view-btn">
                            <Badge bg="default" className="more-text mt-4" style={{fontSize: "18px", background: "white", color: "#00a8a9", borderRadius: "20px", padding: "10px", cursor: "pointer", paddingLeft: "25px", width: "100%"}} onClick={onclickOpenTodayNews}><span style={{color: "#00a8a9", fontWeight: 200}}>Explore More <FontAwesomeIcon icon={faChevronRight} /></span></Badge>
                        </Col>
                    </Row>
                </Container>
            }

            {showOverlay && selectedStory && 
                <div className="overlay" onClick={handleCloseOverlay}>
                    <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
                        <Stories
                            stories={selectedStory}
                            defaultInterval={1500}
                            width="100%"
                            height="100%"
                        />
                        <div className="story-title">
                            {storyTitle}
                        </div>
                        <button type="button" className="close" aria-label="Close" onClick={handleCloseOverlay}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            }
        </>
    );
}

export default Webstories;
