import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import "../css/View.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import NavBar from '../Common/Header';
import Footer from '../Common/Footer';
import ViewPostList from '../Components/ViewPostList';
function View()
{
    let { category, city, subcategory, innercategory, post } = useParams();
    const [postdata, setPostData] = useState({"category": category, "city": city, "subcategory": subcategory, "innercategory": innercategory, "post": post, "listofposts": ""});
    const [navlist, setNavlist] = useState(null);
    const [page_title_firstpart, setPagefirstTitle] = useState("");
    const [page_title_secondpart, setPagesecondTitle] = useState("");
    const [view_post_list, setViewPostList] = useState("");
    const [pagefooter, setPageFooter] = useState(null);
    const fetchPageData = async (category, city, subcategory, innercategory, post) => {
        try {
            
         if(postdata.category != "city-guides")
         {
            innercategory = subcategory;
            subcategory = city;    
            city = "";
            post = innercategory;
            setPostData({"category": category, "city": city, "subcategory": subcategory, "innercategory": innercategory, "post": post, "listofposts": ""});
         }
          const response = await axios.post('https://broadwayoriginals.com/apis/index.php', postdata);
          setNavlist(<NavBar nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></NavBar>);
          setPagefirstTitle(response.data.data.page_title.part1);
          setPagesecondTitle(response.data.data.page_title.part2);
          setViewPostList(<ViewPostList data={JSON.stringify(response.data.data.page_data)}></ViewPostList>);
          setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></Footer>); 
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchPageData(category, city, subcategory, innercategory, post);
    }, [category, city, subcategory, innercategory, post]);
    return(
        <>
            <Container fluid className='top-header'>
            {navlist} 
        </Container>
        <Container>
            {category == "city-guides" && 
            <Row className="mb-3">
                <Col className="view-filter-header">
                    <Stack direction="horizontal" gap={2}>
                        <Badge bg="light" text="dark" ><span style={{color: "#848484"}}>Choose City:</span></Badge>
                        <Badge bg="success" text="dark" className="badge-active" style={{border: "1px solid #848484", borderRadius: "27px", fontWeight: 100}}><span style={{color: "white"}}>Hyderabad</span></Badge>
                        <Badge bg="light" text="dark" style={{border: "1px solid #848484", borderRadius: "27px", fontWeight: 100}}>Mumbai</Badge>
                        <Badge bg="light" text="dark" style={{border: "1px solid #848484", borderRadius: "27px", fontWeight: 100}}>Chennai</Badge>
                        <Badge bg="light" text="dark" style={{border: "1px solid #848484", borderRadius: "27px", fontWeight: 100}}>Kolkota</Badge>
                        <Badge bg="light" text="dark" style={{border: "1px solid #848484", borderRadius: "27px", fontWeight: 100}}>Delhi</Badge>
                        <Badge bg="light" text="dark" style={{border: "1px solid #848484", borderRadius: "27px", fontWeight: 100}}>Kochi</Badge>
                    </Stack>
                </Col>
            </Row>
            }
           
            <Row className="mb-1">
                <Col>
                    <h2><span className='view-page-header'>{page_title_firstpart} </span><span className='view-page-header-span'>{page_title_secondpart}</span></h2>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col className='view-page-content'>
                    <p>Discover a complete guide to where to shop, best places to eat, top bars & nightlife, things to do, top places to see, and best tourist attractions in Delhi, Gurgaon & Noida. Find curated reviews & recommendations</p>
                </Col>
            </Row>
            {view_post_list}
        </Container>
        {pagefooter}
        </>
    );
}


export default View;