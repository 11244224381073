import React from 'react';
import { Container, Navbar, Nav, Image, Card, Button, ListGroup, Row, Col } from 'react-bootstrap';

function FoodArticle() {
  return (
    <div>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand href="#">BROADWAY ORIGINALS</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link href="#">City Guide</Nav.Link>
              <Nav.Link href="#">Life Style</Nav.Link>
              <Nav.Link href="#">Entertainment</Nav.Link>
              <Nav.Link href="#">Travel</Nav.Link>
              <Nav.Link href="#">Food</Nav.Link>
              <Nav.Link href="#">Tech</Nav.Link>
              <Nav.Link href="#">Health</Nav.Link>
              <Nav.Link href="#">Web Stories</Nav.Link>
              <Nav.Link href="#">Videos</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="mt-4">
        <Card className="mb-4">
          <Card.Body>
            <Card.Title>’Tis The Season: 20 Best Christmas Movies To Watch This Year</Card.Title>
            <Row className="mb-4">
              <Col xs={2}>
                <Image src="author-image.jpg" roundedCircle />
              </Col>
              <Col>
                <p>Article by</p>
                <p className="author-name">Tom Cruse Martin King</p>
                <p className="author-stats">2.1K Followers | 1.8K Following</p>
              </Col>
            </Row>
            <Image src="main-image.jpg" fluid className="mb-4" />
            <Card.Text>
              A classic haunt for anyone with a sweet tooth, this classic Bangalore bakery makes the list for their famous plum cake...
            </Card.Text>
            <Image src="pizza-image.jpg" fluid className="mb-4" />
            <Card.Text>
              A classic haunt for anyone with a sweet tooth, this classic Bangalore bakery makes the list for their famous plum cake...
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="mb-4">
          <Card.Body>
            <Card.Title>Mutton Dum Zaffrani Biryani</Card.Title>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <p>Serves 20 | 30 min | 320 kcal</p>
              <Button variant="dark">Print Recipe</Button>
            </div>
            <Card.Subtitle className="mb-2">Ingredients</Card.Subtitle>
            <ListGroup variant="flush">
              <ListGroup.Item>4 cooked lobsters</ListGroup.Item>
              <ListGroup.Item>1/2 Mayonnaise</ListGroup.Item>
              <ListGroup.Item>1/2 celery stalk</ListGroup.Item>
              <ListGroup.Item>Salt and freshly ground black pepper</ListGroup.Item>
              <ListGroup.Item>Rolla, split & lightly toasted</ListGroup.Item>
              <ListGroup.Item>2 Tablespoons chopped parsley leaves</ListGroup.Item>
              <ListGroup.Item>4 chopped potatoes</ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>
        <Card className="mb-4">
          <Card.Body>
            <Card.Title>You may also like</Card.Title>
            <Row>
              <Col sm={4}>
                <Card className="mb-4">
                  <Card.Body>A Peak into Earth Home mysteries by Pavan</Card.Body>
                </Card>
              </Col>
              <Col sm={4}>
                <Card className="mb-4">
                  <Card.Body>A Peak into Earth Home mysteries by Pavan</Card.Body>
                </Card>
              </Col>
              <Col sm={4}>
                <Card className="mb-4">
                  <Card.Body>A Peak into Earth Home mysteries by Pavan</Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <Card className="mb-4">
                  <Card.Body>A Peak into Earth Home mysteries by Pavan</Card.Body>
                </Card>
              </Col>
              <Col sm={4}>
                <Card className="mb-4">
                  <Card.Body>A Peak into Earth Home mysteries by Pavan</Card.Body>
                </Card>
              </Col>
              <Col sm={4}>
                <Card className="mb-4">
                  <Card.Body>A Peak into Earth Home mysteries by Pavan</Card.Body>
                </Card>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card className="mb-4">
          <Card.Body>
            <Card.Title>Related Articles</Card.Title>
            <Row>
              <Col sm={3}>
                <Card className="mb-4">
                  <Card.Body>Autobiographies that can convert you in...</Card.Body>
                </Card>
              </Col>
              <Col sm={3}>
                <Card className="mb-4">
                  <Card.Body>Autobiographies that can convert you in...</Card.Body>
                </Card>
              </Col>
              <Col sm={3}>
                <Card className="mb-4">
                  <Card.Body>Autobiographies that can convert you in...</Card.Body>
                </Card>
              </Col>
              <Col sm={3}>
                <Card className="mb-4">
                  <Card.Body>Autobiographies that can convert you in...</Card.Body>
                </Card>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
      <footer className="bg-dark text-white text-center py-3">
        <p>&copy; 2023 BROADWAY ORIGINALS. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default FoodArticle;
