import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavBar from '../Common/Header';
import Footer from '../Common/Footer';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faFacebook, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import FacebookLogin from 'react-facebook-login';
import 'react-toastify/dist/ReactToastify.css';
import "../css/user.css"
import "../App.css";
import Stack from 'react-bootstrap/Stack';
import Badge from 'react-bootstrap/Badge';
import GoogleLoginComponent from '../Common/GoogleLoginComponent';

function User()
{
    const [data, setData] = useState(null);   
    const [navlist, setNavlist] = useState(null);
    const [pagefooter, setPageFooter] = useState(null);
    const [conformpasswordv, setConformPasswordV] = useState(false);
    const [validationResults, setValidationResults] = useState([]);
    const [formRegData, setFormRegData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        dob: '',
        confpassword: '',
        user_registration: ''
      });
      const [formLogData, setFormLogData] = useState({
        username: '',
        pwd: '',
        user_login: ''
      });
      const validatePasswordConditions = (password) => {
        const validations = [
          // At least one lowercase letter
          {
            condition: /[a-z]/.test(password),
            label: 'At least one lowercase letter'
          },
          // At least one uppercase letter
          {
            condition: /[A-Z]/.test(password),
            label: 'At least one uppercase letter'
          },
          // At least one digit
          {
            condition: /\d/.test(password),
            label: 'At least one digit'
          },
          // At least one special character
          {
            condition: /[@$!%*?&]/.test(password),
            label: 'At least one special character'
          },
          // Minimum length of 8 characters
          {
            condition: password.length >= 8,
            label: 'Minimum length of 8 characters'
          }
        ];
    
        return validations;
    };

    const handleLoginSuccess = (tokenId) => {
        fetch('https://broadwayoriginals.com/apis/loginwith/login_with_google.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: tokenId }),
        })
          .then((response) => response.json())
          .then((data) => {
            if(data.status == "1"){
                sessionStorage.setItem('bdo_fullname', data.fullname);
                sessionStorage.setItem('bdo_id', data.user_id);
                localStorage.setItem('bdo_fullname', data.fullname);
                localStorage.setItem('bdo_id', data.user_id);
                window.location.href = '/dashboard';
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      };

      const responseFacebook = async (response) => {
        if (response.accessToken) {
          try {
            // Send the Facebook access token to the backend
            const res = await axios.post('https://broadwayoriginals.com/apis/loginwith/login_with_fb.php', {
              accessToken: response.accessToken,
            });
            if(res.data.status == "1"){
                sessionStorage.setItem('bdo_fullname', res.data.fullname);
                sessionStorage.setItem('bdo_id', res.data.user_id);
                localStorage.setItem('bdo_fullname', res.data.fullname);
                localStorage.setItem('bdo_id', res.data.user_id);
                window.location.href = '/dashboard';
            }
          } catch (error) {
            console.error('Login Error:', error);
          }
        } else {
          console.error('Facebook login failed.');
        }
      };

 
    const fetchContactUsPageData = async () => {
        try {
          const response = await axios.get('https://broadwayoriginals.com/apis/index.php?contactus');
          setData(response.data);
          setNavlist(<NavBar nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></NavBar>);
          setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></Footer>);
          
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        if(name == "password")
        {
            setValidationResults(validatePasswordConditions(value));
        }
        if(name == "confpassword")
        {
            if(formRegData.password == value)
            {
                setConformPasswordV(true);
                setValidationResults([]);
            }else{
                setConformPasswordV(false);
            }
            
        }
        setFormRegData({
          ...formRegData,
          [name]: value
        });
      };


      const handleInputChange = (e) => {
        const { name, value } = e.target;
        
        setFormLogData({
          ...formLogData,
          [name]: value
        });
      };

      const handleRegSubmit = async (e) => {
        e.preventDefault();
        try {
            if(conformpasswordv !== false)
            {
                var response = await axios.post('https://broadwayoriginals.com/apis/index.php', formRegData);
                if(response.data.status == "1")
                {
                    toast.success(response.data.msg);
                    setFormRegData({
                        firstname: '',
                        lastname: '',
                        email: '',
                        password: '',
                        dob: '',
                        confpassword: '',
                        user_registration: ''
                    });
                    setValidationResults([]);
                }else{
                    toast.error(response.data.msg);
                }
            }
            
          } catch (error) {
            toast.error("Error submitting form");
          }
    };

    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        try {
                var response = await axios.post('https://broadwayoriginals.com/apis/index.php', formLogData);
                if(response.data.status == "1")
                {
                    toast.success(response.data.msg);
                    setFormLogData({
                        username: '',
                        pwd: '',
                        user_login: ''
                    });
                    sessionStorage.setItem('bdo_fullname', response.data.fullname);
                    sessionStorage.setItem('bdo_id', response.data.user_id);
                    localStorage.setItem('bdo_fullname', response.data.fullname);
                    localStorage.setItem('bdo_id', response.data.user_id);
                    window.open('/dashboard');
                    //dashboard
                }
          } catch (error) {
            //toast.error("Error submitting form");
          }
    };

    useEffect(() => {
        const session_bdo_id = sessionStorage.getItem('bdo_id');
        if (session_bdo_id !== "" && session_bdo_id !== undefined && !isNaN(session_bdo_id) && Number(session_bdo_id) > 0) {
            window.location = '/dashboard';
        }else{
            fetchContactUsPageData();
        }
    }, []);
    return(
        <>
            <Container fluid className='top-header'>
            {navlist} 
            </Container>
        <Container className='mt-5' fluid>
                <ToastContainer />
                
                <Container>
                  <Row className='mb-5 mt-2'>
                    <Col xxxl={6} xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} xxs={12} className='mb-5'>
                        <Card className='h-100' style={{background: "#f8f9fa"}}>
                            <Card.Body >
                                <Card.Title className='user-block-header pb-3'>Sign Up</Card.Title>
                                <Card.Text>
                                    <Row>
                                        <Col xxxl={6} xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} xxs={12}>
                                            <Form.Group className="mb-3">
                                                <Form.Control type="text" placeholder="First Name" name="firstname" id="firstname" style={{border: "1px solid var(--Text-Text-Invert, #9D9B9B)"}} value={formRegData.firstname} onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col xxxl={6} xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} xxs={12}>
                                            <Form.Group className="mb-3">
                                                <Form.Control type="text" placeholder="Last Name" name="lastname" id="lastname" style={{border: "1px solid var(--Text-Text-Invert, #9D9B9B)"}} value={formRegData.lastname} onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xxxl={6} xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} xxs={12}>
                                            <Form.Group className="mb-3">
                                                <Form.Control type="email" placeholder="Email" name="email" id="email" style={{border: "1px solid var(--Text-Text-Invert, #9D9B9B)"}} value={formRegData.email} onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col xxxl={6} xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} xxs={12}>
                                            <Form.Group className="mb-3" >
                                                <Form.Control type="date" placeholder="Date of Birth" name="dob" id="dob" style={{border: "1px solid var(--Text-Text-Invert, #9D9B9B)"}} value={formRegData.dob} onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3" >
                                                <Form.Control type="password" placeholder="Password" name="password" id="password" style={{border: "1px solid var(--Text-Text-Invert, #9D9B9B)"}} value={formRegData.password} onChange={handleChange} />
                                                <ul className='view-pass-conditions'>
                                                    {validationResults.map((result, index) => (
                                                    <li key={index} className='user-error-msg'>
                                                        {result.condition ? '✅' : '❌'} {result.label}
                                                    </li>
                                                    ))}
                                                </ul>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3" >
                                                <Form.Control type="password" placeholder="Conform Password" name="confpassword" id="confpassword" style={{border: "1px solid var(--Text-Text-Invert, #9D9B9B)"}} value={formRegData.confpassword} onChange={handleChange} />
                                                {conformpasswordv == false && formRegData.confpassword != ""  && 
                                                    <p className='user-error-msg' style={{color: 'red'}}>Passwords do not match, Please enter correctly.</p>                                              
                                                }
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    
                                    <Row className='mt-3'>
                                        <Col>
                                            <Button variant="info" type="button" className='submit-btn' style={{background: "#00A8AA", color: "white", outline: "#00A8AA", border: "1px solid #00A8AA"}} onClick={handleRegSubmit}>Sign Up</Button>
                                        </Col>
                                    </Row>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xxxl={6} xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} xxs={12} >
                        <Card className='' style={{background: "#f8f9fa"}}>
                            <Card.Body>
                                <Card.Title className='user-block-header pb-3'>Sign In</Card.Title>
                                <Card.Text>
                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3">
                                                <Form.Control type="text" placeholder="Enter Email ID / Username*" name="username" id="username" style={{border: "1px solid var(--Text-Text-Invert, #9D9B9B)"}} value={formLogData.username} onChange={handleInputChange} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3">
                                                <Form.Control type="password" placeholder="Password*" name="pwd" id="pwd" style={{border: "1px solid var(--Text-Text-Invert, #9D9B9B)"}}  value={formLogData.pwd} onChange={handleInputChange} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xxxl={6} xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} xxs={12}>
                                            <Form.Check type="checkbox" id="rememberme" label="Remember me" />
                                        </Col >
                                        <Col xxxl={6} xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} xxs={12}>
                                            <Nav.Link as={NavLink} to="/resetpwd" className='forgot-pwd'><span className='forgot-link'>Forgot password</span></Nav.Link>
                                        </Col>
                                    </Row>
                                    <Row className='pt-3'>
                                        <Col>
                                            <Button variant="info" type="button" className='submit-btn' style={{background: "#00A8AA", color: "white", outline: "#00A8AA", border: "1px solid #00A8AA"}} onClick={handleLoginSubmit}>Sign In</Button>
                                        </Col>
                                    </Row>
                                    <Row className='pt-3'>
                                        <Col style={{textAlign: "center"}}><div className='bold'>OR</div></Col>
                                    </Row>
                                    <Row className='pt-3' >
                                        <Col xxxl={6} xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} xxs={12}  >
                                            <div style={{textAlign: "center", marginLeft: "5px"}} className='mb-3'>
                                                <GoogleLoginComponent onLoginSuccess={handleLoginSuccess} />   
                                            </div>
                                        </Col>
                                        <Col xxxl={6} xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} xxs={12} >
                                            <FacebookLogin
                                                    appId="542100411473056"
                                                    autoLoad={false}
                                                    fields="name,email,picture"
                                                    callback={responseFacebook}
                                                    icon="fa-facebook"
                                                    className='facebook-login'
                                                />
                                        </Col>
                                    </Row>
                                    
                                    
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                  </Row>
                </Container>               
                
        </Container>
        {pagefooter}
        </>
        
    );
}


export default User;