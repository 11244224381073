import React, { useState } from 'react';
import '../css/search.css';
import searchicon from "../images/ion_search-outline.png";
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import axios from 'axios';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';
function Search(){
    const [query, setQuery] = useState('');
    const [searchlist, setSearchList] = useState([]);
    const handleInputChange = async () => {
        if (query.length > 1) {
            const response = await axios.post('https://broadwayoriginals.com/apis/index.php', {'search': query});
            setSearchList(response.data);
        }
    };
    const handleInput = (e) => {
        const value = e.target.value;
        setQuery(value);
    };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleInputChange();
        }
     };
    return (       
        <div>
            <InputGroup>
                <Form.Control
                    placeholder="Search"
                    aria-label="Search"
                    className="searchinput"
                    aria-describedby="basic-addon2" 
                    value={query}
                    onChange={handleInput}
                    onKeyPress={handleKeyPress}
                    style={{borderRight: "0px", borderTopLeftRadius: "15px", borderBottomLeftRadius: "15px", paddingTop: "0.5rem", paddingBottom: "0.5rem"}}
            />
            <InputGroup.Text id="basic-addon2" style={{borderTopRightRadius: "15px", borderBottomRightRadius: "15px", background: "white", cursor: "pointer"}} onClick={handleInputChange}>
                <Image src={searchicon} alt="searchicon" /></InputGroup.Text>
            </InputGroup>
            <ListGroup className='search-dropdown' as="ol">
                {searchlist.length > 0 && searchlist.map((item) =>  
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start "
                    >
                        <Nav.Link as={NavLink} to={"/"+item.custom_url} className="right-float"><div className="ms-2 me-auto">
                        <div className='search-item'>{item.post_title}</div>
                            <span className='auth-info'>{item.author_name}</span>
                        </div></Nav.Link>
                    </ListGroup.Item>
                )}
            </ListGroup>
        </div>
    );
}

export default Search;