import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import View from './pages/View';
import Advertise from './pages/Advertise';
import Withus from './pages/Withus';
import Policy from './pages/Policy';
import User from './pages/User';
import Dashboard from './pages/Dashboard';
import PostView from './pages/PostView';
import FoodArticle from './pages/FoodArticle';
import Videos from './pages/Videos';
import StoriesList from './pages/StoriesList';
import CityGuides from './pages/CityGuides';
import Entertainment from './pages/Entertainment';
import LifeStyle from './pages/LifeStyle';
import Travel from './pages/Travel';
import Food from './pages/Food';
import Money from './pages/Money';
import Health from './pages/Health';
import Technology from './pages/Technology';
import Verify from './pages/Verify';
const routes = [
  { path: '/', component: <Home />, exact: true },
  { path: '/about', component: <About /> },
  { path: '/contact', component: <Contact /> },
  { path: '/view', component: <View /> },
  { path: '/advertise', component: <Advertise /> },
  { path: '/work-with-us', component: <Withus /> },
  { path: '/privacy-policy', component: <Policy /> },
  { path: '/new-user', component: <User /> },
  { path: '/dashboard', component: <Dashboard /> },
  { path: '/:posturl', component: <PostView /> },
  { path: '/food-article', component: <FoodArticle /> },
  { path: '/videos', component: <Videos /> },
  { path: '/videos/:selectsubcategory', component: <Videos /> },
  { path: '/webstories', component: <StoriesList /> },
  { path: '/city-guides', component: <CityGuides /> },
  { path: '/city-guides/:selectcityid', component: <CityGuides /> },
  { path: '/city-guides/:selectcityid/:selectsubcategory', component: <CityGuides /> },
  { path: '/entertainment', component: <Entertainment /> },
  { path: '/entertainment/:selectsubcategory', component: <Entertainment /> },
  { path: '/life-style', component: <LifeStyle /> },
  { path: '/life-style/:selectsubcategory', component: <LifeStyle /> },
  { path: '/travel', component: <Travel /> },
  { path: '/travel/:selectsubcategory', component: <Travel /> },
  { path: '/food', component: <Food /> },
  { path: '/food/:selectsubcategory', component: <Food /> },
  { path: '/money', component: <Money /> },
  { path: '/health', component: <Health /> },
  { path: '/technology', component: <Technology /> },
  { path: '/today-news', component: <View /> },
  { path: '/verify/:token/:mail', component: <Verify /> },
];

export default routes;