import "../css/tranding.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Carousel from "react-multi-carousel";
import trending from '../images/trending-icon.png';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';
function Trending(trandingdata)
{
  const trending_list = JSON.parse(trandingdata.trandingdata);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
    return(
        <Container style={{marginBottom: "60px", display: "block"}}>
                <Row>
                  <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12}>
                      <h4 style={{color: "#00A8AA", marginBottom: "40px"}}><Image src={trending} style={{marginTop: "-7px"}} loading="lazy" /> <span className="block-header">Trending</span></h4>
                </Col>
                </Row>
                <Row>
                      <Carousel responsive={responsive} infinite={true}>
                        {trending_list.map((item, index) =>  
                            <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12} className="mb-3" >
                                <Card className="bg-dark text-white " style={{marginRight: "10px", borderRadius: "20px", marginRight: "15px", height: "330px", width: "80%", border: "none"}}>
                                    <Card.Img src={"https://broadwayoriginals.com/"+item.post_img} alt="image" style={{height: "100%",borderRadius: "20px"}} loading="lazy" />
                                    <Card.ImgOverlay style={{ backgroundColor: "#2125298f", borderRadius: "20px"}}>
                                        <Card.Title ><Nav.Link as={NavLink} to={"/"+item.custom_url} ><div className="webstory_title" style={{bottom: "5%", fontSize: "18px"}}>{item.post_title}</div></Nav.Link></Card.Title>
                                    </Card.ImgOverlay>
                                </Card>
                            </Col>
                        )}
                    </Carousel>                     
                  
                </Row>
        </Container>
        
    );
}

export default Trending;