import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "../css/View.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavBar from '../Common/Header';
import Footer from '../Common/Footer';
function Dashboard()
{
    const [data, setData] = useState(null);
    const [navlist, setNavlist] = useState(null);
    const [pagefooter, setPageFooter] = useState(null);
    const [dispalyloginstatus, setDispalyLoginStatus] = useState(false);
    const fetchContactUsPageData = async () => {
        try {
          const response = await axios.get('https://broadwayoriginals.com/apis/index.php?dashboard');
          document.title = "Broadway Originals | Dashboard";
          setData(response.data);
          setNavlist(<NavBar nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></NavBar>);
          setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></Footer>);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };
    const session_bdo_id = sessionStorage.getItem('bdo_id');
    const bdo_fullname = sessionStorage.getItem('bdo_fullname');
    useEffect(() => {
        if (session_bdo_id !== "" && session_bdo_id !== undefined && !isNaN(session_bdo_id) && Number(session_bdo_id) > 0) {
            setDispalyLoginStatus(false);
        }else{
            setDispalyLoginStatus(true);
        }
        fetchContactUsPageData();
    }, []);
    return(
        <>
            <Container fluid className='top-header'>
            {navlist}  
        </Container>
        <Container>
            <Row className="mb-3">
                <Col className="view-filter-header">
                    <h3 className='app-page-header'>Welcome {bdo_fullname}!</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    {dispalyloginstatus == true && 
                        <>
                            <div className='text-center' style={{fontWeight: "bold", color: "#dc3545"}}>Please log in to view the dashboard.</div>
                        </>
                    }
                </Col>
            </Row>
        </Container>
        {pagefooter}
        </>
    );
}


export default Dashboard;