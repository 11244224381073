import '../css/categoryview.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import cityguides from '../images/city-guide.svg';
import lifestyle from '../images/Lifestyle.svg';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function ViewBlock(nav_list)
{
    const nav_list_json = JSON.parse(nav_list.nav_list);
    const onclickOpenurl = (pageurl) => {
        window.location = pageurl;
    }
    var inc = 0 ;
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 6
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 6
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 4
        }
      };
      const renderItems = (data) => {
        {return data.map(category => (              
            category.data != undefined && category.data != "" && (category.category_name === "City Guides" || category.category_name === "Life Style") ? (
                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className='non-slider'>
                    <Row className={
                    category.category_name === "City Guides" ? 
                    'city-guide-block-gap' : 
                    category.category_name === "Life Style" ? 
                    'life-style-block-gap' : 
                    ''
                }>
                        {(category.category_name === "City Guides" || category.category_name === "Life Style") && 
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                {category.category_name === "City Guides" && 
                                <h5 className='blockheader'><Image src={cityguides} className='blockicon' loading="lazy" /> 
                                <span className='view-block-header' style={{paddingLeft: "17px"}}>{category.category_name}</span></h5>
                                }
                                {category.category_name === "Life Style" && 
                                <h5 className='blockheader'><Image src={lifestyle} className='blockicon' loading="lazy" /> 
                                <span className='view-block-header' style={{paddingLeft: "17px"}}>{category.category_name}</span></h5>
                                }
                            </Col>
                        }
                        {category.category_name === "City Guides" &&
                            <div style={{width: "1000px"}}>
                                {category.data.cities.length > 0 && 
                                    <Row style={{marginTop: "40px"}}>
                                        {category.data.cities.map(city => (
                                            <Col md={3} lg={3} xs={3} sm={3} className='categoryview' style={{marginRight: "10px"}}>
                                                <div className="carousel-item-wrapper" style={{textAlign: "center !important"}}>
                                                    <div style={{width: "100px", textAlign: "center !important"}}> 
                                                        <Image src={"https://broadwayoriginals.com/"+city.image}  style={{width: "80%", height: "auto", border: "1px solid #3daaaa"}} roundedCircle loading="lazy" />
                                                        <div style={{textAlign: "center !important", marginTop: "7px"}}><span className='city-guide-link' style={{textAlign: "center !important"}} onClick={() => onclickOpenurl("/" + category.custom_url + "/" + city.urlstring)}>{city.name}</span></div>
                                                    </div>
                                                </div>
                                            </Col>
                                        ))}   
                                    </Row>                
                                } 
                            </div>
                        }
                        {category.category_name == "Life Style" && 
                                    <div style={{width: "1000px"}}>
                                        {category.data.sub_categories.length > 0 && 
                                        <Row style={{marginTop: "40px"}}>
                                            {category.data.sub_categories.map(sub_category => (
                                                <Col md={3} lg={3} xs={4} xxl={3} sm={3} className='categoryview' style={{marginRight: "10px"}}>
                                                <>
                                                <div className="carousel-item-wrapper" style={{textAlign: "center !important"}}>
                                                    <div style={{width: "100px", textAlign: "center !important"}}> 
                                                    <Image src={"https://broadwayoriginals.com/"+sub_category.img} style={{width: "80%", height: "auto", border: "1px solid #3daaaa"}} roundedCircle loading="lazy"/>
                                                    <div style={{textAlign: "center !important", marginTop: "7px"}}><span className='city-guide-link' style={{textAlign: "center !important"}} onClick={() => onclickOpenurl("/" + category.custom_url)}>{sub_category.name}</span></div>
                                                    </div>
                                                    </div>
                                                </>
                                                </Col>
                                            ))}
                                        </Row>
                                        }
                                     
                                    </div>
                                }
                    </Row>
                </Col>
                ) : (
                    <></>
                )
            ))}
    };

    const renderSliderList = (data) => {
        {return data.map(category => (              
            category.data != undefined && category.data != "" && (category.category_name === "City Guides" || category.category_name === "Life Style") ? (
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className='list-slider-view mb-3'>
                    <Row >
                        {(category.category_name === "City Guides" || category.category_name === "Life Style") && 
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className='mb-3'>
                                {category.category_name === "City Guides" && 
                                <h5 className='blockheader'><Image src={cityguides} className='blockicon' loading="lazy" /> 
                                <span className='view-block-header' style={{paddingLeft: "17px"}}>{category.category_name}</span></h5>
                                }
                                {category.category_name === "Life Style" && 
                                <h5 className='blockheader'><Image src={lifestyle} className='blockicon' loading="lazy" /> 
                                <span className='view-block-header' style={{paddingLeft: "17px"}}>{category.category_name}</span></h5>
                                }
                            </Col>
                        }
                        {category.category_name === "City Guides" &&
                            <div style={{width: "1000px"}}>
                                {category.data.cities.length > 0 && 
                                    <Carousel responsive={responsive} arrows={false} infinite={true} containerClass="carousel-container" itemClass="carouselitempadding" >
                                        {category.data.cities.map(city => (
                                            <Col md={3} lg={3} xs={4} className='categoryview' style={{textAlign: "center !important"}}>
                                                <div className="carousel-item-wrapper" style={{textAlign: "center !important"}}>
                                                    <div style={{width: "100px", textAlign: "center !important"}}> 
                                                        <Image src={"https://broadwayoriginals.com/"+city.image} style={{width: "70%"}} roundedCircle loading="lazy"  />
                                                        <div style={{textAlign: "center !important", marginTop: "7px"}}><span className='city-guide-link' style={{textAlign: "center !important"}} onClick={() => onclickOpenurl("/" + category.custom_url + "/" + city.urlstring)}>{city.name}</span></div>
                                                    </div>
                                                </div>
                                            </Col>
                                        ))}   
                                    </Carousel>             
                                } 
                            </div>
                        }
                        {category.category_name == "Life Style" && 
                                    <div style={{width: "1000px"}}>
                                        {category.data.sub_categories.length > 0 && 
                                        <Carousel responsive={responsive} arrows={false} infinite={true} containerClass="carousel-container" itemClass="carouselitempadding">
                                            {category.data.sub_categories.map(sub_category => (
                                                <Col md={3} lg={3} xs={4} xxl={3} sm={4} className='categoryview' style={{textAlign: "center !important"}}>
                                                    <div className="carousel-item-wrapper" style={{textAlign: "center !important"}}>
                                                        <div style={{width: "100px", textAlign: "center !important"}}>
                                                        <Image src={"https://broadwayoriginals.com/"+sub_category.img} style={{width: "70%"}} roundedCircle loading="lazy"  />                                             
                                                         <div style={{textAlign: "center !important", marginTop: "7px"}}><span className='city-guide-link' style={{textAlign: "center !important"}} onClick={() => onclickOpenurl("/" + category.custom_url)}>{sub_category.name}</span></div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))}
                                        </Carousel>
                                        }
                                     
                                    </div>
                                }
                    </Row>
                </Col>
                ) : (
                    <></>
                )
            ))}
    };


    return(
        <Container className='city-guide-block'>
            <Row >
                {renderItems(nav_list_json)}
            </Row>
            <Row >
                {renderSliderList(nav_list_json)}
            </Row>
        </Container>
    );
}

export default ViewBlock;