import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavBar from '../Common/Header';
import Footer from '../Common/Footer';
import Card from 'react-bootstrap/Card';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Image from 'react-bootstrap/Image';
import "../App.css";
function About()
{
    const [data, setData] = useState(null);
    const [navlist, setNavlist] = useState(null);
    const [pagefooter, setPageFooter] = useState(null);
    const [pagecontent, setPageContent] = useState(null);
    const [pageMembers, setPageMembers] = useState(null);
    const [loaderstate, setLoader] = useState(true);
    const renderMembers = (data) => {
        {return data.map(members => (  
            <Col >
                <Card style={{ width: '100%', background: "#F5F5F5", boxShadow: "0px 4px 15px 0px #00000040",padding: "50px"}} className="text-center h-100">
                    <Card.Header style={{border: "none", background: "none"}}><Image src={members.img} alt="member" /></Card.Header>
                    <Card.Body>
                        <Card.Title style={{color: "#00A8AA"}}>{members.full_name}</Card.Title>
                        <Card.Text style={{color: "#00A8AA"}}>
                            {members.aff}
                        </Card.Text>
                        <Card.Text>
                            {members.bio}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>             
        ))}
    };
    const fetchContactUsPageData = async () => {
        try {
          const response = await axios.get('https://broadwayoriginals.com/apis/index.php?about-us');
          document.title = "Broadway Originals | About Us";
          setData(response.data);
          setNavlist(<NavBar nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></NavBar>);
          setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></Footer>);
          setPageContent(response.data.pagedata.content);
          if(response.data.pagedata.members !=="")
          {
            setPageMembers(response.data.pagedata.members); 
          }
          setLoader(false);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        setLoader(true);
        fetchContactUsPageData();
    }, []);
    return(
        
        <>
        <Container fluid className='top-header'>
            {navlist} 
        </Container>
        
            <Container fluid>
                <ToastContainer />
                
                <Container>
                    <Row>
                        <Col md={12} sm={12} lg={12} xl={12} xxl={12}>
                            <h3 className='app-page-header'>About Us</h3>
                        </Col>
                    </Row>
                    {loaderstate === true && 
                <Row >
                    <Col >
                        <div className="preloader">
                            <div className="preloader-animation">
                                <div className="dot dot-1"></div>
                                <div className="dot dot-2"></div>
                                <div className="dot dot-3"></div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                                <defs>
                                <filter id="goo">
                                    <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur"></feGaussianBlur>
                                    <feColorMatrix in="blur" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7"></feColorMatrix>
                                </filter>
                                </defs>
                            </svg>
                        </div>
                    </Col>
                </Row>}
                    <Row>
                        <Col md={12} sm={12} lg={12} xl={12} xxl={12}>
                            <div className='privacy-policy' dangerouslySetInnerHTML={{ __html: pagecontent }} />
                        </Col>
                    </Row>
                    <Row style={{paddingBottom: "50px"}}>
                        {pageMembers !== null &&
                        <>
                            {renderMembers(pageMembers)}
                        </>
                        }
                       
                    </Row>
                    
                </Container>               
                
            </Container>
            {pagefooter}
        </>
    );
}


export default About;