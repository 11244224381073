import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavBar from '../Common/Header';
import Footer from '../Common/Footer';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../App.css";
function Advertise()
{
    const [data, setData] = useState(null);
    const [navlist, setNavlist] = useState(null);
    const [pagefooter, setPageFooter] = useState(null);
    const [formData, setFormData] = useState({
        fullname: '',
        email: '',
        phonenumber: '',
        companyname: '',
        website: '',
        adsize: ''
      });
    const fetchContactUsPageData = async () => {
        try {
          const response = await axios.get('https://broadwayoriginals.com/apis/index.php?advertise');
          document.title = "Broadway Originals | Advertise";
          setData(response.data);
          setNavlist(<NavBar nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></NavBar>);
          setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></Footer>);
          
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            var response = await axios.post('https://broadwayoriginals.com/apis/index.php', formData);
            if(response !="")
            {
                if(response.data['status'] == "1")
                {
                    toast.success(response.data['msg']);
                    setFormData({
                        fullname: '',
                        email: '',
                        phonenumber: '',
                        companyname: '',
                        website: '',
                        adsize: ''
                    });
                }else{
                    toast.error(response.data['msg']);
                }
                
            }
          } catch (error) {
            toast.error("Error submitting form");
          }
    };

    useEffect(() => {
        fetchContactUsPageData();
    }, []);
    return(
        <>
        <Container fluid className='top-header'>
            {navlist} 
        </Container>
        <Container fluid>
                <ToastContainer />
                <Container>
                    <Row>
                        <Col md={12} sm={12} lg={12} xl={12} xxl={12}>
                            <h3 className='app-page-header'>Advertise</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} sm={12} lg={6} xl={6} xxl={6}>
                            <Form>
                                <Form.Group className="mb-3" >
                                    <Form.Control type="text" placeholder="Full Name*" name="fullname" id="fullname" value={formData.fullname} onChange={handleChange} />
                                    <Form.Text className="text-muted" style={{display: "none"}}>
                                    
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3" >
                                    <Form.Control type="email" placeholder="Email Address*" name="email" id="email" value={formData.email} onChange={handleChange} />
                                    <Form.Text className="text-muted" style={{display: "none"}}>
                                   
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3" >
                                    <Form.Control type="text" placeholder="Phone Number*" name="phonenumber" id="phonenumber" value={formData.phonenumber} onChange={handleChange} />
                                    <Form.Text className="text-muted" style={{display: "none"}}>                                
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3" >
                                    <Form.Control type="text" placeholder="Company Name*" name="companyname" id="companyname" value={formData.companyname} onChange={handleChange} />
                                    <Form.Text className="text-muted" style={{display: "none"}}>                                
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3" >
                                    <Form.Control type="text" placeholder="Website*" name="website" id="website" value={formData.website} onChange={handleChange} />
                                    <Form.Text className="text-muted" style={{display: "none"}}>                                
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3" >
                                    <Form.Text style={{color: "#131313"}}>Choose Ad size</Form.Text>
                                    <div style={{display: "flex", color: "#131313"}}>
                                        <Form.Check type="radio" label="1200 X 300 - $40" style={{marginRight: "20px", color: "#131313"}} name="adsize" value="1200 X 300 - $40" onChange={handleChange} checked={formData.adsize === '1200 X 300 - $40'} />
                                        <Form.Check type="radio" label="300 X 300 - $10" name="adsize" value="300 X 300 - $10" onChange={handleChange} checked={formData.adsize === '300 X 300 - $10'} />

                                    </div>
                                </Form.Group>
                                
                                <Button variant="info" type="button" className='submit-btn mb-5' style={{background: "#00A8AA", color: "white", outline: "#00A8AA", border: "1px solid #00A8AA"}} onClick={handleSubmit}>
                                    Send
                                </Button>
                            </Form>
                        </Col>
                        <Col md={6} sm={12} lg={6} xl={6} xxl={6}>
                            <div><h6 style={{color: "#00A8AA"}}>Disclaimer</h6></div>
                            <p>After submitting your request, our team will reach out to you in 24 hours on email or phone for images and further details.</p>
                        </Col>
                    </Row>
                </Container>               
                
        </Container>
        {pagefooter}
        </>
        
    );
}


export default Advertise;