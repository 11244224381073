import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavBar from '../Common/Header';
import Footer from '../Common/Footer';
import Card from 'react-bootstrap/Card';
import "../App.css";
function Withus()
{
    const [data, setData] = useState(null);
    const [navlist, setNavlist] = useState(null);
    const [pagefooter, setPageFooter] = useState(null);
    const [pagedata, setPageData] = useState(null);
    const fetchContactUsPageData = async () => {
        try {
          const response = await axios.get('https://broadwayoriginals.com/apis/index.php?work-with-us');
          document.title = "Broadway Originals | Work with us";
          setData(response.data);
          setNavlist(<NavBar nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></NavBar>);
          setPageData(response.data.pagedata);
          setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></Footer>);      
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchContactUsPageData();
    }, []);
    const renderJobs = (data) => {
        {return data.map(job => (  
            <Col lg={6} md={6} xs={12} style={{marginBottom: "20px"}}>
                <Card style={{ width: '100%', background: "rgb(255 255 255)", boxShadow: "0px 4px 15px 0px #00000040",padding: "10px", fontFamily: "Roboto", border: "1px solid white"}} className="h-100">
                    <Card.Body>
                        <Card.Title style={{color: "#00A8AA"}}>{job.title}</Card.Title>
                        <Card.Text style={{color: "#00A8AA", fontSize: "13px", margin: "0px"}}>
                            Experience Required: {job.experience}
                        </Card.Text>
                        <Card.Text style={{color: "#00A8AA", fontSize: "13px", margin: "0px"}}>
                            Date: {job.post_on}
                        </Card.Text>
                        <Card.Text style={{color: "#131313", fontSize: "13px"}}>
                            {job.description}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>           
            
        ))}
    };
    return(
        <>
            <Container fluid className='top-header'>
            {navlist} 
        </Container>
        <Container fluid>
                <Container>
                    <Row>
                        <Col md={12} sm={12} lg={12} xl={12} xxl={12}>
                            <h3 className='app-page-header'>Work with us</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className='common-font'>Email your resume to <span><a href="mailto: career@broadwayorginals.com" style={{color: "#00A8AA", textDecoration: "none"}}>career@broadwayorginals.com</a></span></p>
                        </Col>
                    </Row>
                    <Row style={{marginBottom: "50px"}}>
                        {pagedata !== null &&
                        <>
                            {renderJobs(pagedata)}
                        </>
                        }
                    </Row>
                </Container>               
                
        </Container>
        {pagefooter}
        </>
        
    );
}


export default Withus;