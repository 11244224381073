import React from 'react';
import "../css/Header.css";
import logo from "../images/black.png";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCircleUser } from "@fortawesome/free-regular-svg-icons";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from 'react-router-dom';
import Search from './Search';
import { faWhatsapp, faFacebookF, faXTwitter, faLinkedinIn, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";

function Header({ nav_list, socailmedia }) {
    const nav_list_json = JSON.parse(nav_list);
    const socailmedia_json = JSON.parse(socailmedia);
    const menuiconview = <FontAwesomeIcon icon={faBars} className="header-icon" />;
    const pageUrl = (str) => str.toLowerCase().replace(/\s+/g, '-');
    const session_bdo_id = sessionStorage.getItem('bdo_id');
    const bdo_fullname = sessionStorage.getItem('bdo_fullname');
    const shouldShowInitial = !isNaN(session_bdo_id) && Number(session_bdo_id) > 0 && bdo_fullname.trim() !== '';
    const firstLetter = shouldShowInitial ? bdo_fullname.charAt(0).toUpperCase() : '';
    const renderMainNavItems = (data) => {
        return data.map(category => (
            category.data !== undefined ? (
                <NavDropdown title={category.category_name} key={category.category_name}>
                    {category.category_name === "City Guides" &&
                        <div style={{ width: "1000px" }}>
                            {category.data.cities.length > 0 &&
                                <Row>
                                    {category.data.cities.map(city => (
                                        <Col md={3} sm={12} key={city.name}>
                                            <h6 className="memu-header">{city.name}</h6>
                                            {category.data.sub_categories.map(sub_category => (
                                                <NavDropdown.Item
                                                    as={NavLink}
                                                    to={pageUrl("/" + category.custom_url + "/" + city.urlstring + "/" + sub_category.custom_url)}
                                                    className="menu-text"
                                                    key={sub_category.name}
                                                >
                                                    {sub_category.name}
                                                </NavDropdown.Item>
                                            ))}
                                        </Col>
                                    ))}
                                </Row>
                            }
                        </div>}
                    {category.category_name !== "City Guides" &&
                        category.data.sub_categories.map(sub_category => (
                            <NavDropdown.Item
                                as={NavLink}
                                to={pageUrl("/" + category.custom_url + "/" + sub_category.custom_url)}
                                className="menu-text"
                                key={sub_category.name}
                            >
                                {sub_category.name}
                            </NavDropdown.Item>
                        ))
                    }
                </NavDropdown>
            ) : (
                <Nav.Link
                    as={NavLink}
                    to={pageUrl("/" + category.custom_url)}
                    className="header-link"
                    key={category.category_name}
                >
                    {category.category_name}
                </Nav.Link>
            )
        ));
    };

    const renderNavItems = (data) => {
        return data.map(category => (
            category.data !== undefined ? (
                <NavDropdown title={category.category_name} className="dropdown-menu-link" key={category.category_name}>
                    {category.category_name === "City Guides" &&
                        <div style={{ width: "1000px" }}>
                            {category.data.cities.length > 0 &&
                                <Row>
                                    {category.data.cities.map(city => (
                                        <Col md={3} sm={12} key={city.name}>
                                            <h6 className="memu-header">{city.name}</h6>
                                            {category.data.sub_categories.map(sub_category => (
                                                <NavDropdown.Item
                                                    as={NavLink}
                                                    to={pageUrl("/" + category.custom_url + "/" + city.urlstring + "/" + sub_category.custom_url)}
                                                    className="menu-text"
                                                    key={sub_category.name}
                                                >
                                                    {sub_category.name}
                                                </NavDropdown.Item>
                                            ))}
                                        </Col>
                                    ))}
                                </Row>
                            }
                        </div>}
                    {category.category_name !== "City Guides" &&
                        category.data.sub_categories.map(sub_category => (
                            <NavDropdown.Item
                                as={NavLink}
                                to={pageUrl("/" + category.custom_url + "/" + sub_category.custom_url)}
                                className="menu-text"
                                key={sub_category.name}
                            >
                                {sub_category.name}
                            </NavDropdown.Item>
                        ))
                    }
                </NavDropdown>
            ) : (
                <NavDropdown.Item
                    as={NavLink}
                    to={pageUrl("/" + category.custom_url)}
                    className="menu-text"
                    key={category.category_name}
                >
                    {category.category_name}
                </NavDropdown.Item>
            )
        ));
    };

    return (
        <Container className="header-block">
            <Row className="mobile-nav-header">
                <Col xs={2} md={1} lg={1}>
                    <Navbar.Brand>
                        <NavDropdown title={menuiconview} className="iconmenu iconmenu-dropdown-menu">
                            <NavDropdown.Item>Welcome to Broadways <FontAwesomeIcon icon={faXmark} style={{ marginLeft: "35px" }} /></NavDropdown.Item>
                            {renderNavItems(nav_list_json)}
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={NavLink} to="/advertise">Advertise</NavDropdown.Item>
                            <NavDropdown.Item as={NavLink} to="/work-with-us">Work With Us</NavDropdown.Item>
                            <NavDropdown.Item as={NavLink} to="/about">About Us</NavDropdown.Item>
                            <NavDropdown.Item as={NavLink} to="/contact">Contact Us</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <div className="icon-dropdown-block">
                                {socailmedia_json.map(mediadata => (
                                    <NavDropdown.Item as={NavLink} to={mediadata.link} target="_blank" key={mediadata.name} style={{ paddingRight: "0px" }}>
                                        <FontAwesomeIcon icon={mediadata.name === "facebook" ? faFacebookF :
                                            mediadata.name === "twitter" ? faXTwitter :
                                                mediadata.name === "indeed" ? faLinkedinIn :
                                                    mediadata.name === "instagram" ? faInstagram :
                                                        faYoutube} className="icon-dropdown" />
                                    </NavDropdown.Item>
                                ))}
                            </div>
                        </NavDropdown>
                    </Navbar.Brand>
                </Col>
                <Col xs={8} md={3} lg={3} >
                    <Navbar.Brand as={NavLink} to="/"> <Image src={logo} alt="logo" className="logo" /></Navbar.Brand>
                </Col>
                <Col md={4} lg={4} className="col-hide">
                    <Search />
                </Col>
                <Col md={3} lg={3} className="textright col-hide" style={{ paddingTop: "8px" }}>
                    {socailmedia_json.map(mediadata => (
                        <span style={{ paddingRight: "8px" }} key={mediadata.name}>
                            <a href={mediadata.link} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={mediadata.name === "facebook" ? faFacebookF :
                                    mediadata.name === "twitter" ? faXTwitter :
                                        mediadata.name === "indeed" ? faLinkedinIn :
                                            mediadata.name === "instagram" ? faInstagram :
                                                faYoutube} className="header-icon" style={{ fontSize: "30px", cursor: "pointer", color: "black" }} />
                            </a>
                        </span>
                    ))}
                </Col>
                <Col xs={2} md={1} lg={1} sm={2} xxs={2} className="textright login-icon d-flex justify-content-end align-items-center">
                    {shouldShowInitial ? (
                        <Nav.Link as={NavLink} to="/dashboard" className='' style={{textAlign: "right"}}><span className='login-name-text'>
                        {firstLetter}
                    </span></Nav.Link>
                    ) : (
                        <Nav.Link as={NavLink} to="/new-user">
                            <FontAwesomeIcon icon={faCircleUser} className="header-icon" style={{ fontSize: "30px" }} />   
                        </Nav.Link>
                    )}
                </Col>
            </Row>
            <Row>
                <Col md={12} xs={12} className="mobile-search-block">
                    <Search />
                </Col>
            </Row>
            <Row className="col-hide mb-3" style={{ marginTop: "1.25rem" }}>
                <Col md={12} className="col-hide">
                    <Navbar expand="lg">
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll">
                            <Nav
                                className="me-auto my-2 my-lg-0"
                                style={{ maxHeight: '100px', marginLeft: '-9px' }}
                                navbarScroll
                            >
                                {renderMainNavItems(nav_list_json)}
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Col>
            </Row>
        </Container>
    );
}

export default Header;
