import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import loader from "../images/loader5.gif";
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavBar from '../Common/Header';
import Footer from '../Common/Footer';
import Alert from 'react-bootstrap/Alert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Image from 'react-bootstrap/Image';
import "../App.css";
function Verify()
{
    let { token, mail } = useParams();
    const [loaderstate, setLoader] = useState(true);
    const [data, setData] = useState(null);
    const [navlist, setNavlist] = useState(null);
    const [pagefooter, setPageFooter] = useState(null);
    const [pagecontent, setPageContent] = useState("");
    const fetchPageData = async (token, mail) => {
        try {
          const response = await axios.get('https://broadwayoriginals.com/apis/index.php?email_verification=&token='+token+"&mail="+mail);
          setLoader(false);
          setData(response.data);
          setPageContent(response.data.pagedata);
          setNavlist(<NavBar nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></NavBar>);
          setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></Footer>);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };
   
    useEffect(() => {
        fetchPageData(token, mail);
    }, [token, mail]);
    return(
        <>
            <Container fluid className='top-header'>
            {navlist} 
        </Container>
        <Container fluid>
                <ToastContainer />
                
                <Container>
                    <Row>
                        <Col md={12} sm={12} lg={12} xl={12} xxl={12}>
                            <h3 className='app-page-header'>Email Verification</h3>
                        </Col>
                    </Row>
                    <Row>
                        {loaderstate === true && 
                        <Col md={12} sm={12} lg={12} xl={12} xxl={12} className='overlay' style={{background: "#201e1e", opacity: "0.9"}}>
                            
                                <Image src={loader} alt="Loader" className="loader-img" />
                        </Col>
                        }
                        <Col md={12} sm={12} lg={12} xl={12} xxl={12}>
                        {pagecontent!="" && pagecontent.status != 'undefined' && pagecontent.status == "1" &&
                            <Alert variant="success" className='text-center mt-5'>
                                <Alert.Heading>{pagecontent.msg}</Alert.Heading>
                                <hr />
                                <p className="mb-0">
                                    Please login <a href="/new-user">Sign In</a>
                                </p>
                            </Alert>
                        }
                        {pagecontent!="" && pagecontent.status != 'undefined' && pagecontent.status == "0" &&
                            <Alert variant="danger" className='text-center mt-5'>
                                <Alert.Heading style={{color: "brown"}}>{pagecontent.msg}</Alert.Heading>                             
                            </Alert>
                        }
                        </Col>
                    </Row>
                </Container>               
                
        </Container>
        {pagefooter}
        </>
        
    );
}


export default Verify;