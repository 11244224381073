// src/components/GoogleLoginComponent.js
import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

const GoogleLoginComponent = ({ onLoginSuccess }) => {
  const clientId = '1025142309416-pon5siunqku3hvfv6ukbbes0ks6ieepe.apps.googleusercontent.com';

  const onSuccess = (response) => {
    onLoginSuccess(response.credential);
  };

  const onError = () => {
    console.log('Login Failed');
  };

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <GoogleLogin
        onSuccess={onSuccess}
        onError={onError}
      />
    </GoogleOAuthProvider>
  );
};

export default GoogleLoginComponent;
