import React, { useEffect } from 'react';

function MetaTags({ tags }) {
    // Remove any existing meta tags with the specified names
    const existingMetaTags = document.querySelectorAll('meta[name="keywords"], meta[name="description"]');
    existingMetaTags.forEach(tag => tag.parentNode.removeChild(tag));

    // Create new meta tags based on the provided data
    tags.forEach(tag => {
      const newMetaTag = document.createElement('meta');
      newMetaTag.setAttribute('name', tag.tag_name);
      newMetaTag.setAttribute('content', tag.tag_content);
      document.head.appendChild(newMetaTag);
    });

    // Clean up: Remove all created meta tags when component unmounts or tags change
    return () => {
      const addedMetaTags = document.querySelectorAll('meta[name="keywords"], meta[name="description"]');
      addedMetaTags.forEach(tag => tag.parentNode.removeChild(tag));
    };
}

export default MetaTags;
