import React, { useState } from 'react';
import '../css/common.css';
import '../css/Video.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import ListGroup from 'react-bootstrap/ListGroup';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faCirclePlay } from "@fortawesome/free-regular-svg-icons";
import Badge from 'react-bootstrap/Badge';

function Video(videoslist) {
  const videos_list = JSON.parse(videoslist.videoslist);
  const [showVideo, setShowVideo] = useState(false);
  const onclickOpenPages = () => {
    window.location = "/videos";
  }
  const handleClick = () => {
    setShowVideo(true);
  };

  return (
    <>
      {videos_list && videos_list.length > 0 &&
        <Container className="text-white py-4">
          <Row className='mb-4'>
            <Col>
              <h4 className="text-info">
                <FontAwesomeIcon icon={faCirclePlay} className="me-2" />
                Videos
              </h4>
            </Col>
            <Col className="text-end">
              <Nav.Link as={NavLink} to="/videos" className='view-more-btn text-white fs-5 desktop-view-btn' style={{width: "auto"}} >
                <span className='video-more'>Explore More <FontAwesomeIcon icon={faChevronRight} /></span>
              </Nav.Link>
            </Col>
          </Row>
          <Row>
            <Col lg={7} md={12}>
              {videos_list.map((item, index) => index === 0 && (
                <Card key={index} className="mb-3" style={{border: "none", background: "none", borderRadius: "20px"}}>
                  <Card.Body style={{padding: "0px"}}>
                    {showVideo ? (
                      <iframe
                        width="100%"
                        height="350"
                        src={item.video_link}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    ) : (
                      <div onClick={handleClick}>
                        <img src={'https://broadwayoriginals.com/' + item.post_img} alt="Video Cover" loading="lazy" style={{width: "100%", borderRadius: "20px"}} />
                      </div>
                    )}
                    <Card.Title style={{marginTop: "13px"}}><Image src={"https://broadwayoriginals.com/"+item.author.img}  roundedCircle className="final-au-img"  loading="lazy" /> <span className="final-au-name" style={{color: "white"}} >{item.author.name}</span></Card.Title>
                    <Card.Title ><h6  className="card-row-title"><Nav.Link as={NavLink} to={"/"+item.custom_url}><span className="final-post-title" style={{color: "white"}}>{item.post_title}</span></Nav.Link></h6></Card.Title>
                  
                    
                  </Card.Body>
                </Card>
              ))}
            </Col>
            <Col lg={5} md={12}>
              {videos_list.map((item, index) => index !== 0 && (
                <Row key={index} className='mb-3'>
                  <Col xs={5} className='mb-2'>
                    <Image src={'https://broadwayoriginals.com/' + item.post_img} className="w-100" loading="lazy" />
                  </Col>
                  <Col xs={7} className='mb-2'>
                    <Nav.Link as={NavLink} to={"/" + item.custom_url} className='text-white'>
                      <h6 className='small-video-title mb-2'>{item.post_title}</h6>
                    </Nav.Link>
                    <p className='video-details'>
                      <div>{item.post_views ? item.post_views : 0}</div>
                      <div>Uploaded by {item.author.name}</div>
                    </p>
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
          <Row>
              <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{padding: "0px", textAlign: 'end'}} className="mobile-view-btn">
                <Badge bg="default" className="more-text mt-4" style={{fontSize: "18px", background: "none", color: "#00a8a9", borderRadius: "20px", padding: "10px", cursor: "pointer", paddingLeft: "25px", width: "100%", border: "1px solid #00a8a9"}} onClick={onclickOpenPages}><span style={{color: "#00a8a9", fontWeight: 200}}><span style={{float: "left"}}>Explore More</span> <FontAwesomeIcon icon={faChevronRight} style={{float: "right"}} /></span></Badge>
              </Col>
          </Row>
        </Container>
      }
    </>
  );
}

export default Video;
