import "../css/Entertainment.css";
import entertainmenticon from "../images/Entertainment.svg";
import movie_img from "../images/movies_img.png";
import korean_img from "../images/korean.png";
import tv_series from "../images/tv_series.png";
import lasttest from "../images/latest_trailerss.png";
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faTape } from "@fortawesome/free-solid-svg-icons";
import Badge from 'react-bootstrap/Badge';
import Image from 'react-bootstrap/Image';

function Entertainment() {
    const onclickOpenEntertainment = () => {
        window.location = "/entertainment";
    }
    return (
        <Container style={{ marginBottom: "50px", paddingTop: "61px" }}>
            <Row>
                <Col xxl={10} xl={10} lg={10} md={10} sm={12} xs={12}>
                    <h4 className="block-header"><Image src={entertainmenticon} loading="lazy" className="section-block-img-icon" /> Entertainment</h4>
                </Col>
                <Col xxl={2} xl={2} lg={2} md={2} sm={12} xs={12} className="desktop-view-btn" style={{padding: "0px", textAlign: 'end'}}>
                    <Badge bg="default" className="more-text " style={{fontSize: "18px", background: "white !important", color: "#00a8a9", borderRadius: "20px", padding: "10px", cursor: "pointer", paddingLeft: "25px"}} onClick={onclickOpenEntertainment}><span style={{color: "#00a8a9", fontWeight: 200}}>Explore More <FontAwesomeIcon icon={faChevronRight} style={{fontSize: "15px", paddingLeft: "10px"}} /></span></Badge>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col xs={12} md={6} className="mb-4 mb-md-0">
                    <Card className="bg-dark text-white h-100" style={{borderRadius: "21px", border: "none"}}>
                        <Card.Img src={movie_img} alt="Card image" style={{ height: "100%" }} loading="lazy" />
                        <Card.ImgOverlay style={{borderRadius: "21px", backgroundColor: "rgb(0 0 0 / 33%)"}}>
                            <Card.Title>
                                <Nav.Link as={NavLink} to="/entertainment/movies">
                                    <div className="entertainment-title">Movies</div>
                                </Nav.Link>
                            </Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                </Col>
                <Col xs={12} md={6} style={{ textAlign: "end" }}>
                    <Card className="bg-dark text-white mb-4" style={{ borderRadius: "21px", border: "none"}}>
                        <Card.Img src={korean_img} alt="Card image" loading="lazy" />
                        <Card.ImgOverlay style={{borderRadius: "21px", backgroundColor: "rgb(0 0 0 / 33%)"}}>
                            <Card.Title>
                                <Nav.Link as={NavLink} to="/entertainment/korean">
                                    <div className="entertainment-title1">Korean</div>
                                </Nav.Link>
                            </Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                    <Card className="bg-dark text-white mb-4" style={{ borderRadius: "21px", border: "none"}}>
                        <Card.Img src={tv_series} alt="Card image" loading="lazy" />
                        <Card.ImgOverlay style={{borderRadius: "21px", backgroundColor: "rgb(0 0 0 / 33%)"}}>
                            <Card.Title>
                                <Nav.Link as={NavLink} to="/entertainment/tv-series">
                                    <div className="entertainment-title1">TV Series</div>
                                </Nav.Link>
                            </Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                    <Card className="bg-dark text-white" style={{ borderRadius: "21px", border: "none"}}>
                        <Card.Img src={lasttest} alt="Card image" loading="lazy" />
                        <Card.ImgOverlay style={{borderRadius: "21px", backgroundColor: "rgb(0 0 0 / 33%)"}}>
                            <Card.Title>
                                <Nav.Link as={NavLink} to="/entertainment/latest-trailers">
                                    <div className="entertainment-title1">Latest Trailers</div>
                                </Nav.Link>
                            </Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                </Col>
            </Row>
            <Row className="d-block d-md-none mt-4">
                <Col xs={12}>
                    <Badge bg="default" className="more-text mt-4" style={{fontSize: "18px", background: "none", color: "#00a8a9", borderRadius: "20px", padding: "10px", cursor: "pointer", paddingLeft: "25px", width: "100%", border: "1px solid #00a8a9"}} onClick={onclickOpenEntertainment}><span style={{color: "#00a8a9", fontWeight: 200}}><span style={{float: "left"}}>Explore More</span> <FontAwesomeIcon icon={faChevronRight} style={{float: "right"}} /></span></Badge>
                </Col>
            </Row>
        </Container>
    );
}

export default Entertainment;
