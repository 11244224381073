import "../css/View.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye} from "@fortawesome/free-regular-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from 'react-router-dom'; 
import Nav from 'react-bootstrap/Nav';
function ViewPostList(data)
{
    const data_list = JSON.parse(data.data);
    const currentUrl = window.location.href;
    let newUrl = currentUrl;
    if (newUrl.endsWith('/')) {
        newUrl = newUrl.slice(0, -1);
    }
    return (
        <>
        {data_list!=="" && 
            <>
                {data_list.map((item, index) =>  
                <>
                {item.posts_count > 0 && 
                    <>
                        {item.sub_category_status != "0" &&
                        <Row className='mb-2'>
                            <Col md={6} sm={6} lg={6}>  
                                <h5><span className="view-page-sub-header">{item.name.part1} </span><span className="view-page-sub-header-span">{item.name.part2}</span></h5>
                            </Col>
                            <Col md={6} sm={6} lg={6} className="more-link">
                            
                            <Nav.Link as={NavLink} to={newUrl+"/"+item.custom_url} className="right-float"><span className="inner-block-span">Explore More <FontAwesomeIcon icon={faChevronRight} style={{fontSize: "15px"}} /></span></Nav.Link>
                                
                            </Col>
                        </Row>
                        }
                    
                    <Row className='mb-4'>
                    {item.posts.map((post, index1) =>   
                        <Col sm={3} md={3} lg={3} className="mb-3">
                             <Card style={{padding: "20px", borderRadius: "10px"}} className="h-100">
                                <Card.Img variant="top" src={"https://broadwayoriginals.com/"+post.post_img} />
                                <Card.Body style={{padding: "0px", marginTop: "20px" }}>
                                    <Card.Title style={{padding: "0px !important"}}><Nav.Link as={NavLink} to={"/"+post.custom_url} className="right-float"><h6  className="card-row-title">{post.post_title}</h6></Nav.Link></Card.Title>
                                    <span style={{paddingRight: "10px"}}><FontAwesomeIcon icon={faEye} /></span> <span>{post.post_views == "" ? 0: post.post_views}</span>
                                </Card.Body>
                            </Card> 
                        </Col>
                    )}
                    </Row>
                    </>
                }
                </>
            )}
            </>
        }
        </>
        
    );
}

export default ViewPostList;