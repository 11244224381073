import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavBar from '../Common/Header';
import Footer from '../Common/Footer';
import Image from 'react-bootstrap/Image';
import Stories from 'react-insta-stories';
import "../css/Webstories.css";
function StoriesList(){
    const [navlist, setNavlist] = useState(null);
    const [showOverlay, setShowOverlay] = useState(false);
    const [selectedStory, setSelectedStory] = useState(null);
    const [storyTitle, setStoryTitle] = useState("");
    const [pagefooter, setPageFooter] = useState(null);
    const [pagecontent, setPageContent] = useState([]);
    const [formData, setFormData] = useState({
        webstories: '',
      }); 
    const fetchPageData = async () => {
        try {
          var response = await axios.post('https://broadwayoriginals.com/apis/index.php', formData);
          setNavlist(<NavBar nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></NavBar>); 
          setPageContent(response.data.data);       
          setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></Footer>);        
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };
    const handleCloseOverlay = () => {
      setSelectedStory(null); 
      setStoryTitle(""); 
      setShowOverlay(false);
  };

  const handleShowOverlay = (story, title) => {
      setSelectedStory(story);
      setStoryTitle(title);
      setShowOverlay(true);
  };
    useEffect(() => {
        fetchPageData();
    }, []);
    return(
        <>
          {navlist}
          <Container>
            <Row>
                  <Col>
                      <h2><span className='view-page-header'>Webstories </span></h2>
                  </Col>
              </Row>
          </Container>
          {pagecontent.length > 0 &&
                <Container className="mt-3" style={{ marginBottom: "60px" }}>                
                    <Row >
                        {pagecontent.map((item, index) => (
                            <Col key={index} xxxl={3} xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} xxs={12}>
                                <div className="story-item" onClick={() => handleShowOverlay(item.files, item.title)}>
                                    <Image src={"https://broadwayoriginals.com/"+item.image} fluid />
                                    <div className="story-title">{item.title}</div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            }
            {showOverlay && selectedStory && 
                <div className="overlay" onClick={handleCloseOverlay}>
                    <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
                        <Stories
                            stories={selectedStory}
                            defaultInterval={1500}
                            width="100%"
                            height="100%"
                        />
                        <div className="story-title">
                            {storyTitle}
                        </div>
                        <button type="button" className="close" aria-label="Close" onClick={handleCloseOverlay}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            }
          {pagefooter}
        </>
    );
}
export default StoriesList;