import "../css/Travel.css";
import travelicon from "../images/site-images/travel.png";
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faTape } from "@fortawesome/free-solid-svg-icons";
import Badge from 'react-bootstrap/Badge';
function Travel(data)
{
    const data_list = JSON.parse(data.data);
    const onclickOpenTravel = () => {
        window.location = "/travel";
    }
    return(
        <Container style={{marginBottom: "50px"}} >
            <Row >
                <Col xxl={10} xl={10} lg={10} md={10} sm={12} xs={12}>
                   <h4 className="block-header"><Image src={travelicon} loading="lazy" /> Travel</h4>
                </Col>
                <Col xxl={2} xl={2} lg={2} md={2} sm={12} xs={12} className="desktop-view-btn" style={{padding: "0px", textAlign: 'end'}}>
                   <Badge bg="default" className="more-text " style={{fontSize: "18px", background: "white !important", color: "#00a8a9", borderRadius: "20px", padding: "10px", cursor: "pointer", paddingLeft: "25px"}} onClick={onclickOpenTravel}><span style={{color: "#00a8a9", fontWeight: 200}}>Explore More <FontAwesomeIcon icon={faChevronRight} style={{fontSize: "15px", paddingLeft: "10px"}} /></span></Badge>
                </Col>
            </Row>
            <Row className="mt-5"> 
                    {data_list.map((item1) => 
                        <>
                            <Col style={{textAlign: "end"}} xxxl={6} xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} xxs={12}>
                                <Card className="bg-dark text-white mb-2" style={{borderRadius: "21px", border: "none"}}>
                                    <Card.Img src={"https://broadwayoriginals.com/"+item1.post_img} alt="image" className="travel-img" loading="lazy" style={{borderRadius: "20px"}} />
                                    <Card.ImgOverlay className="travel-overlay-img-bg" style={{borderRadius: "21px"}}>
                                        <div className="travel-img-block">
                                            <Card.Title >
                                            <Nav.Link as={NavLink} to={"/"+item1.custom_url} ><h6 className="card-row-title-travel">{item1.post_title}</h6></Nav.Link>
                                            </Card.Title>
                                            <ListGroup style={{paddingTop: "15px"}}>
                                                    <ListGroup.Item
                                                        as="li"
                                                        className="d-flex justify-content-between align-items-start" style={{border: "none", padding: "0px",  background: "none", textAlign: "left"}}
                                                    >           
                                                        <div className="ms-2 me-auto">
                                                        <Image src={"https://broadwayoriginals.com/"+item1.author.img} roundedCircle style={{width: "7%"}} loading="lazy" /> <span className="author-name">{item1.author.name}</span>
                                                        </div>
                                                    </ListGroup.Item>
                                                </ListGroup>
                                        </div>               
                                    </Card.ImgOverlay>
                                    
                                </Card>
                            </Col>
                            
                        
                        </>
                    )}
            </Row>
            <Row>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{padding: "0px", textAlign: 'end'}} className="mobile-view-btn mb-3">
                    <Badge bg="default" className="more-text mt-4" style={{fontSize: "18px", background: "none", color: "#00a8a9", borderRadius: "20px", padding: "10px", cursor: "pointer", paddingLeft: "25px", width: "100%", border: "1px solid #00a8a9"}} onClick={onclickOpenTravel}><span style={{color: "#00a8a9", fontWeight: 200}}><span style={{float: "left"}}>Explore More</span> <FontAwesomeIcon icon={faChevronRight} style={{float: "right"}} /></span></Badge>
                </Col>
            </Row>
        </Container>
        
    );
}

export default Travel;