import React, {  useState } from 'react';
import "../css/Footer.css";
import footerimage from "../images/white.png";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Image from 'react-bootstrap/Image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faFacebookF, faXTwitter, faLinkedinIn, faInstagram , faYoutube} from "@fortawesome/free-brands-svg-icons";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Footer(nav_list) {
    const nav_list_json = JSON.parse(nav_list.nav_list);
    const socailmedia_json = JSON.parse(nav_list.socailmedia);
    const [newsletteremail, setnewsletterEmail] = useState("");
    const renderMainNavItems = (data) => {
        {return data.map(category => (              
            category.data != undefined ? (
                <Col md={6} sm={6} xs={6} xxs={6}><Nav.Link as={NavLink} to={"/"+category.custom_url} className="header-link"><span className="footer-nav-link">{category.category_name}</span></Nav.Link></Col>
            ) : (
                <Col md={6} sm={6} xs={6} xxs={6}><Nav.Link as={NavLink} to="/" className="header-link"><span className="footer-nav-link">{category.category_name}</span></Nav.Link></Col>
            )
        ))}
    };
    const handlNewsLetter = async () => {
        try {
            if(newsletteremail !=""){
                const response = await axios.get('https://broadwayoriginals.com/apis/index.php?newsletter='+newsletteremail);
                if(response.data.status == "1"){
                    toast.success(response.data.msg);
                    setnewsletterEmail("");
                }else{
                    toast.error(response.data.msg);
                }
                
            } 
          } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const updatelNewsLetter = (event) => {
        setnewsletterEmail(event.target.value);
    };
    return (
      <Container className='footer mt-5' fluid>
        <Container style={{paddingTop: "60px", paddingBottom: "60px"}}>
            <ToastContainer />
        <Row className="mb-5">
          <Col xxxl={3} xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} xxs={12} className="mb-5">
                <div>
                    <Image src={footerimage}  alt="footer image" style={{width: "70%"}} />
                </div>
                <div>
                    <Nav style={{marginTop: "27px"}}>
                    {socailmedia_json.map(mediadata => (
                        <>
                            {mediadata.name === "facebook" &&
                                <Nav.Link href={mediadata.link} target="_blank" style={{padding: "0px"}}>
                                    <FontAwesomeIcon icon={faFacebookF} className="footer-icon" />
                                </Nav.Link>
                            }
                            {mediadata.name === "twitter" &&
                                <Nav.Link href={mediadata.link} target="_blank" style={{padding: "0px"}}>
                                 <FontAwesomeIcon icon={faXTwitter} className="footer-icon" />
                                </Nav.Link>  
                            }
                            {mediadata.name === "indeed" &&
                                <Nav.Link href={mediadata.link} target="_blank" style={{padding: "0px"}}>
                                    <FontAwesomeIcon icon={faLinkedinIn} className="footer-icon" />
                                </Nav.Link>
                            }
                            {mediadata.name === "instagram" &&
                                 <Nav.Link href={mediadata.link} target="_blank" style={{padding: "0px"}}>
                                    <FontAwesomeIcon icon={faInstagram} className="footer-icon" />
                                 </Nav.Link>     
                            }
                            {mediadata.name === "youtube" &&
                                 <Nav.Link href={mediadata.link} target="_blank" style={{padding: "0px"}}>
                                    <FontAwesomeIcon icon={faYoutube} className="footer-icon" />
                                </Nav.Link>      
                            }
                        </>
                    ))}
                        
                    </Nav>
                </div>
          </Col>
          <Col xxxl={3} xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} xxs={12} className="mb-5">
                <h5 className="footer-head">Most Searches</h5>
                <Row>
                    {renderMainNavItems(nav_list_json)}
                </Row>
          </Col>
          <Col xxxl={3} xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} xxs={12} className="mb-5">
                <h5 className="footer-head">Help</h5>
                <div >
                    <Nav style={{display: "list-item"}}>
                        <Nav.Link as={NavLink} to="/advertise" style={{padding: "0px"}}> 
                           <span className="footer-nav-link">Advertise</span> 
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/contact" style={{padding: "0px"}}>
                            <span className="footer-nav-link">Contact Us</span>
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/about" style={{padding: "0px"}}>
                            <span className="footer-nav-link">About Us</span>
                        </Nav.Link> 
                        <Nav.Link as={NavLink} to="/work-with-us" style={{padding: "0px"}}>
                            <span className="footer-nav-link">Work with Us</span>
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/privacy-policy" style={{padding: "0px"}}>
                            <span className="footer-nav-link">Privacy Policy</span>
                        </Nav.Link>
                    </Nav>
                </div>
                
          </Col>
          <Col xxxl={3} xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} xxs={12}>
                <h5 className="footer-head">Newsletter</h5>
                <p>
                    Subscribe to mailing list to receive daily updates to your inbox.
                </p>
                <InputGroup className="mb-3">
                    <Form.Control
                    placeholder="Your Email Address"
                    aria-label="Your Email Address"
                    aria-describedby="basic-addon2" style={{borderRight: "none"}} value={newsletteremail}  onChange={updatelNewsLetter}
                    />
                    <InputGroup.Text id="basic-addon2" style={{background: "#00A8AA", color: "white", border: "5px", cursor: "pointer"}}  onClick={handlNewsLetter}>Subscribe</InputGroup.Text>
                </InputGroup>
          </Col>
        </Row>
        <Row>
            <Col>
                <p className="copyrights">Copyright @ 2023 Designed & Developed by BnB Infotech Freelancing Team, All Rights Reserved</p>
                
            </Col>
        </Row>
        </Container>      
      </Container>
    );
  }
  
  export default Footer;