import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavBar from '../Common/Header';
import Footer from '../Common/Footer';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../App.css";
function Contact()
{
    const [data, setData] = useState(null);
    const [navlist, setNavlist] = useState(null);
    const [pagefooter, setPageFooter] = useState(null);
    const [formData, setFormData] = useState({
        fullname: '',
        email: '',
        phonenumber: '',
        query: '',
        contact_us_submittion: ''
      });
    const fetchContactUsPageData = async () => {
        try {
          const response = await axios.get('https://broadwayoriginals.com/apis/index.php?contactus');
          document.title = "Broadway Originals | Contact Us";
          setData(response.data);
          setNavlist(<NavBar nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></NavBar>);
          setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></Footer>);
          
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            var response = await axios.post('https://broadwayoriginals.com/apis/index.php', formData);
            if(response !="")
            {
                toast.success(response.data[0]);
                setFormData({
                    fullname: '',
                    email: '',
                    phonenumber: '',
                    query: '',
                    contact_us_submittion: ''
                  });
            }
          } catch (error) {
            toast.error("Error submitting form");
          }
    };

    useEffect(() => {
        fetchContactUsPageData();
    }, []);
    return(
        <>
            <Container fluid className='top-header'>
            {navlist} 
        </Container>
        <Container fluid>
                <ToastContainer />
                
                <Container>
                    <Row>
                        <Col md={12} sm={12} lg={12} xl={12} xxl={12}>
                            <h3 className='app-page-header'>Contact</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} sm={12} lg={6} xl={6} xxl={6}>
                            <Form>
                                <Form.Group className="mb-3" >
                                    <Form.Control type="text" placeholder="Enter Full Name" name="fullname" id="fullname" value={formData.fullname} onChange={handleChange} />
                                    <Form.Text className="text-muted" style={{display: "none"}}>
                                    
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3" >
                                    <Form.Control type="email" placeholder="Enter Email" name="email" id="email" value={formData.email} onChange={handleChange} />
                                    <Form.Text className="text-muted" style={{display: "none"}}>
                                   
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3" >
                                    <Form.Control type="text" placeholder="Enter Phone Number" name="phonenumber" id="phonenumber" value={formData.phonenumber} onChange={handleChange} />
                                    <Form.Text className="text-muted" style={{display: "none"}}>
                                    
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3" >
                                    <Form.Control as="textarea" aria-label="query" placeholder="Enter Message" name="query" id="query" rows={8} value={formData.query} onChange={handleChange} />
                                    <Form.Text className="text-muted" style={{display: "none"}}>
                                    
                                    </Form.Text>
                                </Form.Group>
                                <Button variant="info" type="button" className='submit-btn mb-5' style={{background: "#00A8AA", color: "white", outline: "#00A8AA", border: "1px solid #00A8AA"}} onClick={handleSubmit}>
                                    Send
                                </Button>
                            </Form>
                        </Col>
                        <Col md={6} sm={12} lg={6} xl={6} xxl={6}>
                            <Card className="mb-4">
                                <Card.Body className='contact-block' style={{color: "white", outline: "#00A8AA", border: "1px solid #00A8AA"}}>
                                    <div className="mb-4" style={{display: "flex"}}>
                                        <div style={{width: "50%"}}>
                                            <h6>Email</h6>
                                            <span>abcedfghij@email.com</span>
                                        </div>
                                        <div style={{width: "50%"}}>
                                            <h6>Phone</h6>
                                            <span>1234567890</span>
                                        </div>
                                    </div>
                                    <div>
                                        <h6>Address</h6>
                                        <span>Sector 2-1-23, Hyderabad, India - 500001</span>
                                    </div>
                                </Card.Body>
                            </Card>

                            <Card className="mb-5">
                                <Card.Body className='map-block' style={{color: "white", outline: "#00A8AA", border: "1px solid #00A8AA"}}>
                                    <div >
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.3581876670382!2d78.48334857683966!3d17.394589860062357!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99c56b6a0d5d%3A0xeeac5299de9bc88!2s23%2C%202%2C%20Street%201%2C%20Venkateshwara%20Colony%2C%20King%20Koti%2C%20Hyderguda%2C%20Hyderabad%2C%20Telangana%20500095!5e0!3m2!1sen!2sin!4v1713555873123!5m2!1sen!2sin" width="600" height="200" style={{border:0}} loading="lazy" ></iframe>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>               
                
        </Container>
        {pagefooter}
        </>
        
    );
}


export default Contact;