import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "../css/cityguide-main-page.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faBookmark} from "@fortawesome/free-regular-svg-icons";
import loader from "../images/loader5.gif";
import { NavLink } from 'react-router-dom'; 
import Nav from 'react-bootstrap/Nav';
import NavBar from '../Common/Header';
import Footer from '../Common/Footer';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import PaginationBlock from '../Components/PaginationBlock';
import MetaTags from '../Common/MetaTags';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Technology()
{
    const [data, setData] = useState(null);
    const [pagemetatags, setPageMetaTags] = useState([]);
    const [navlist, setNavlist] = useState(null);
    const [pagefooter, setPageFooter] = useState(null);
    const [contentpara, setContentPara] = useState('');
    const [loaderstate, setLoader] = useState(true);

    const [pagetitle1, setPageTitle1] = useState(null);
    const [pagetitle2, setPageTitle2] = useState(null);
 
    const [formData, setFormData] = useState({
        technology: '',
        page: ""
      });
    const fetchPageData = async () => {
        try {
          const response = await axios.post('https://broadwayoriginals.com/apis/index.php', formData);
          setData(response.data.data.page_data);  
          const citynames= "";
          setPageTitle1(response.data.data.page_title.part1);
          setPageTitle2(response.data.data.page_title.part2);
          document.title = "Broadway Originals | "+response.data.data.page_title.part1+" "+response.data.data.page_title.part2;
          setContentPara('Discover a complete guide to where to '+response.data.topics+' in '+citynames+'. Find curated reviews & recommendations.');
          setNavlist(<NavBar nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></NavBar>);
          setPageMetaTags(response.data.data.meta_tags);
          setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></Footer>);
          setLoader(false);
          //setTopics(response.data.topics);
          
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };

    const fetchPages = async (city_id,sub_category_id,inner_category_id, page_number) => {      
        try { 
            setLoader(true);       
            setData(null);
            const response = await axios.post('https://broadwayoriginals.com/apis/index.php', {technology: "", page: page_number});
            setData(response.data.data.page_data);
            
            const citynames1= '';
            setPageTitle1(response.data.data.page_title.part1);
            setPageTitle2(response.data.data.page_title.part2);
            document.title = "Broadway Originals | "+response.data.data.page_title.part1+" "+response.data.data.page_title.part2;
            setPageMetaTags(response.data.data.meta_tags);
            setContentPara('Discover a complete guide to where to '+response.data.topics+' in '+citynames1+'. Find curated reviews & recommendations.');
            setNavlist(<NavBar nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></NavBar>);
            setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></Footer>);
            setLoader(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const savePost = async (page_post_id) => {
        try {
                const session_bdo_id = sessionStorage.getItem('bdo_id');
                if (session_bdo_id !== "" && session_bdo_id !== undefined && !isNaN(session_bdo_id) && Number(session_bdo_id) > 0) {
                    var response = await axios.post('https://broadwayoriginals.com/apis/index.php', {savepost: "", post_id: page_post_id, user_id: session_bdo_id});
                    if(response.data.status == "1")
                    {
                       // setPostSaveStatus(1);
                    }else{
                        toast.error("try again");
                    }
                }else{
                    toast.error("Please log in to save this post.");
                }
                
          } catch (error) {
            
          }
    };
    useEffect(() => {
        fetchPageData();
    }, []);
    return(
        <>
            <Container fluid className='top-header'>
            {loaderstate === true && 
                <Row >
                    <Col className='overlay'>
                        <Image src={loader} alt="Loader" className="loader-img" />
                    </Col>
                </Row>}
                <MetaTags tags={pagemetatags} />
            {navlist} 
            <ToastContainer />
        </Container>
        <Container>       
            <Row>
                <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12}>
                {pagetitle1!=null && pagetitle1!="" && pagetitle2!=null && pagetitle2!="" &&
                    <h2><span className='view-page-header'>{pagetitle1} </span><span className='view-page-header-span'> {pagetitle2}</span></h2>
                }
                </Col>
            </Row>
            <Row className='mb-3'>
              {contentpara!='' &&
                <Col className='view-page-content'>
                    <p>{contentpara}</p>
                </Col>
                }
            </Row>
            {data!=null && data!="" && 
                <Row>
                {data.map((item1) =>  
                    <>
                        {item1.posts_count > 0 && 
                            <>
                             <Row className='mb-2'>
                                 <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12}>  
                                    {item1.total_pages == undefined &&
                                        <h5><span className="view-page-sub-header">Explore </span><span className="view-page-sub-header-span">{item1.name}</span></h5>
                                    }
                                 </Col>
                                
                             </Row>
                             <Row className='mb-2'>
                                 {item1.posts.map((post) =>   
                                     <Col xxxl={3} xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} xxs={12} className="mb-3 mobile-pad" >
                                         <Card style={{padding: "20px", borderRadius: "10px", border: "none"}} className="h-100">
                                             <Card.Img variant="top" src={"https://broadwayoriginals.com/"+post.post_img} />
                                             <Card.Body style={{padding: "0px", marginTop: "20px" }}>
                                                 <Card.Title style={{padding: "0px !important"}}><Nav.Link as={NavLink} to={"/"+post.custom_url} className="right-float"><h6  className="card-row-title">{post.post_title}</h6></Nav.Link></Card.Title>
                                                 <div>
                                                    <span style={{paddingRight: "10px"}}><FontAwesomeIcon icon={faEye} /> {post.post_views == "" ? 0: post.post_views}</span>
                                                    <span style={{paddingRight: "10px", float: "right", cursor: "pointer"}}><FontAwesomeIcon icon={faBookmark} onClick={() => savePost(post.id)} /></span> 
                                                 </div>
                                             </Card.Body>
                                         </Card> 
                                     </Col>
                                 )}
                             </Row>
                             
                             <Row>
                                <Col>
                                {item1.total_pages !== undefined && item1.total_pages > 1 &&
                                    <PaginationBlock  total_pages={item1.total_pages} active_page={item1.active_page} city_id="" sub_category_id="" inner_category_id="" fetchPages={fetchPages}></PaginationBlock>                                    
                                }
                                </Col>
                             </Row>
                            </>
                        }
                    </>
                   
                )}     
                </Row>
            }
        </Container>
        {pagefooter}
        </>
    );
}


export default Technology;