// ProgressBar.jsx
import React, { useState } from 'react';
import '../css/ProgressBar.css'; // Make sure to create this CSS file

const ProgressBar = ({ progress }) => {
  const [showBubble, setShowBubble] = useState(true);
  const bubbleStyle = {
    left: `${progress}%`,
  };

  return (
    <div
      className="progress-container"
      onMouseEnter={() => setShowBubble(true)}
      onMouseLeave={() => setShowBubble(true)}
    >
      <div
        className="progress-bar"
        style={{ width: `${progress}%` }}
      />
      <div class="bubble">50%</div>
      
    </div>
  );
};

export default ProgressBar;
