import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "../css/Home.css";
import "../App.css";
import "../css/animations.css";
import bgimage from "../images/curve.svg";
import loader from "../images/loader5.gif";
import bgimage1 from "../images/background2.png";
import Banner from '../Components/Banner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ViewBlock from '../Components/ViewBlock';
import Trending from '../Components/Trending';
import Video  from '../Components/Video';
import WhatsNewToday from '../Components/WhatsNewToday';
import Entertainment from '../Components/Entertainment';
import Technology from '../Components/Technology';
import Webstories from '../Components/Webstories';
import NavBar from '../Common/Header';
import Footer from '../Common/Footer';
import Travel from '../Components/Travel';
import Money from '../Components/Money';
import Food from '../Components/Food';
import Health from '../Components/Health';
import Image from 'react-bootstrap/Image';
import MetaTags from '../Common/MetaTags';
function Home()
{
    const [data, setData] = useState(null);
    const [banners, setBanners] = useState(null);
    const [navlist, setNavlist] = useState(null);
    const [categories, setCategories] = useState(null);
    const [trandingblock, setTranding] = useState(null);
    const [whatstoday, setWhatsToday] = useState(null);
    const [videosblock, setVideos] = useState(null);
    const [technologyblock, setTechnology] = useState(null);
    const [webstoriesblock, setWebstories] = useState(null);
    const [trvelblock, setTravel] = useState(null);
    const [moneyblock, setMoney] = useState(null);
    const [healthblock, setHealth] = useState(null);
    const [foodblock, setFood] = useState(null);
    const [pagefooter, setPageFooter] = useState(null);
    const [loaderstate, setLoader] = useState(true);
    const [pagemetatags, setPageMetaTags] = useState([]);
    const fetchHomePageData = async () => {
        try {
          
          const response = await axios.get('https://broadwayoriginals.com/apis/index.php?home-page-data');
          setData(response.data);
          setPageMetaTags(response.data.meta_tags);
          document.title = "Broadway Originals | Home";
          setNavlist(<NavBar nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></NavBar>);
          setBanners(<Banner bannerslist={JSON.stringify(response.data.banners)}></Banner>);
          setCategories(<ViewBlock nav_list={JSON.stringify(response.data.navlist)}></ViewBlock>);
          setTranding(<Trending trandingdata={JSON.stringify(response.data.trending)}></Trending>);
          setWhatsToday(<WhatsNewToday todaynews={JSON.stringify(response.data.todaynews)}></WhatsNewToday>);
          setVideos(<Video videoslist={JSON.stringify(response.data.videoslist)}></Video>);
          setTechnology(<Technology todaynews={JSON.stringify(response.data.technology)}></Technology>);
          setWebstories(<Webstories data={JSON.stringify(response.data.webstories)}></Webstories>);
          setTravel(<Travel data={JSON.stringify(response.data.travel)}></Travel>);
          setFood(<Food foodlist={JSON.stringify(response.data.food)}></Food>);
          setMoney(<Money todaynews={JSON.stringify(response.data.money)}></Money>);
          setHealth(<Health todaynews={JSON.stringify(response.data.health)}></Health>);
          setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></Footer>);
          setLoader(false);
          
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchHomePageData();
    }, []);
    return(
        <>
            {navlist}
            {banners}
            <Container>
                {loaderstate === true && 
                <Row >
                    <Col >
                        <div className="preloader">
                            <div className="preloader-animation">
                                <div className="dot dot-1"></div>
                                <div className="dot dot-2"></div>
                                <div className="dot dot-3"></div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                                <defs>
                                <filter id="goo">
                                    <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur"></feGaussianBlur>
                                    <feColorMatrix in="blur" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7"></feColorMatrix>
                                </filter>
                                </defs>
                            </svg>
                        </div>
                    </Col>
                </Row>}
                <MetaTags tags={pagemetatags} />
            </Container>       
            <Container fluid>
                <div className='home-bg1'>
                    <Image src={bgimage} alt="bgimage" style={{width: "100%", opacity: "35%"}} />
                </div>
                {categories}
                <Row ><Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}></Col></Row>         
                {trandingblock}
                <Row ><Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}></Col></Row>
                {whatstoday}
                <Row ><Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}></Col></Row>
            </Container>
            <Container fluid style={{background: "#131313"}}>
                {videosblock}
                <Row ><Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}></Col></Row>
            </Container>    
            <Container fluid className='main-section-bg2'>
            {loaderstate !== true && 
                <Entertainment></Entertainment>
            }
            <Row ><Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}></Col></Row>
                {technologyblock}  
                <Row ><Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}></Col></Row> 
                {trvelblock}
            </Container>  
            <Container fluid>
                <Row ><Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}></Col></Row>
                {healthblock}
                <Row ><Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}></Col></Row>
                {foodblock}
                <Row ><Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}></Col></Row>
                {moneyblock}
                
            </Container>         
            {pagefooter}
        </>
    );
}


export default Home;