import React from 'react';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';

function Banner({ bannerslist }) {
  const banners_list = JSON.parse(bannerslist);

  const aspectRatioStyle = {
    position: 'relative',
    width: '100%',
    paddingTop: '56.25%', // 16:9 aspect ratio (9/16 * 100)
  };

  const imageStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover', // ensures the image covers the area
  };

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col xs={12} md={12} lg={12} style={{ padding: '0px' }}>
          <Carousel>
            {banners_list.map((item, index) => (
              <Carousel.Item key={index}>
                <div style={aspectRatioStyle}>
                  <Image
                    src={"https://broadwayoriginals.com/" + item.image}
                    alt="banner"
                    style={imageStyle}
                    loading="lazy"
                  />
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>
    </Container>
  );
}

export default Banner;
