import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavBar from '../Common/Header';
import Footer from '../Common/Footer';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../App.css";
function Policy()
{
    const [data, setData] = useState(null);
    const [navlist, setNavlist] = useState(null);
    const [pagefooter, setPageFooter] = useState(null);
    const [pagecontent, setPageContent] = useState(null);
    const fetchContactUsPageData = async () => {
        try {
          const response = await axios.get('https://broadwayoriginals.com/apis/index.php?privacy-policy');
          document.title = "Broadway Originals | Privacy Policy";
          setData(response.data);
          setNavlist(<NavBar nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></NavBar>);
          setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></Footer>);
          setPageContent(response.data.pagedata.content);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchContactUsPageData();
    }, []);
    return(
        
        <>
        <Container fluid className='top-header'>
            {navlist} 
        </Container>
        
            <Container fluid>
                <ToastContainer />
                
                <Container>
                    <Row>
                        <Col md={12} sm={12} lg={12} xl={12} xxl={12}>
                            <h3 className='app-page-header'>Privacy Policy</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} sm={12} lg={12} xl={12} xxl={12}>
                            <div className='privacy-policy' dangerouslySetInnerHTML={{ __html: pagecontent }} />
                        </Col>
                    </Row>
                </Container>               
                
            </Container>
            {pagefooter}
        </>
    );
}


export default Policy;