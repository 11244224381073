import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "../css/cityguide-main-page.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye} from "@fortawesome/free-regular-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import loader from "../images/loader5.gif";
import { NavLink } from 'react-router-dom'; 
import Nav from 'react-bootstrap/Nav';
import NavBar from '../Common/Header';
import Footer from '../Common/Footer';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import PaginationBlock from '../Components/PaginationBlock';
import MetaTags from '../Common/MetaTags';
import { useParams } from 'react-router-dom';

function Videos()
{
    let { selectsubcategory } = useParams();
    const [data, setData] = useState(null);
    const [pagemetatags, setPageMetaTags] = useState([]);
    const [navlist, setNavlist] = useState(null);
    const [pagefooter, setPageFooter] = useState(null);
    const [contentpara, setContentPara] = useState('');
    const [loaderstate, setLoader] = useState(true);
    const [allcities, setAllCities] = useState(null);
    const [pagetitle1, setPageTitle1] = useState(null);
    const [pagetitle2, setPageTitle2] = useState(null);
 
    const [formData, setFormData] = useState({
        videos: '',
        subcategory: '',
        innercategory: '',
        page: ""
      });
    const fetchPageData = async () => {
        try {
          const response = await axios.post('https://broadwayoriginals.com/apis/index.php', formData);
          setData(response.data.data.page_data);  
          setAllCities(response.data.allcities);
          const citynames= "";
          setPageTitle1(response.data.data.page_title.part1);
          setPageTitle2(response.data.data.page_title.part2);
          document.title = "Broadway Originals | "+response.data.data.page_title.part1+" "+response.data.data.page_title.part2;
          setContentPara('Discover a complete guide to where to '+response.data.topics+' in '+citynames+'. Find curated reviews & recommendations.');
          setNavlist(<NavBar nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></NavBar>);
          setPageMetaTags(response.data.data.meta_tags);
          setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></Footer>);
          setLoader(false);
          //setTopics(response.data.topics);
          
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };

    const fetchSubCategoryPageData = async (subcategory_id) => {    
        try { 
            setLoader(true);       
            setData(null);
            const response = await axios.post('https://broadwayoriginals.com/apis/index.php', {videos: "", subcategory: subcategory_id});
            setData(response.data.data.page_data);
            const citynames1= '';
            setPageTitle1(response.data.data.page_title.part1);
            setPageTitle2(response.data.data.page_title.part2);
            document.title = "Broadway Originals | "+response.data.data.page_title.part1+" "+response.data.data.page_title.part2;
            setContentPara('Discover a complete guide to where to '+response.data.topics+' in '+citynames1+'. Find curated reviews & recommendations.');
            setPageMetaTags(response.data.data.meta_tags);
            setNavlist(<NavBar nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></NavBar>);
            setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></Footer>);
            setLoader(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const fetchSubCategoryUrlPageData = async (sub_category_url) => {    
        try { 
            setLoader(true);       
            setData(null);
            const response = await axios.post('https://broadwayoriginals.com/apis/index.php', {videos: "", sub_category_url: sub_category_url});
            setData(response.data.data.page_data);
            const citynames1= '';
            setPageTitle1(response.data.data.page_title.part1);
            setPageTitle2(response.data.data.page_title.part2);
            document.title = "Broadway Originals | "+response.data.data.page_title.part1+" "+response.data.data.page_title.part2;
            setContentPara('Discover a complete guide to where to '+response.data.topics+' in '+citynames1+'. Find curated reviews & recommendations.');
            setPageMetaTags(response.data.data.meta_tags);
            setNavlist(<NavBar nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></NavBar>);
            setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></Footer>);
            setLoader(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const fetchInnerCategoryPageData = async (subcategory_id,inner_category_id) => {    
        try { 
            setLoader(true);     
            setData(null);
            const response = await axios.post('https://broadwayoriginals.com/apis/index.php', {videos: "", subcategory: subcategory_id, innercategory: inner_category_id});
            setData(response.data.data.page_data);
            const citynames1= "";
            setPageMetaTags(response.data.data.meta_tags);
            setPageTitle1(response.data.data.page_title.part1);
            setPageTitle2(response.data.data.page_title.part2);
            document.title = "Broadway Originals | "+response.data.data.page_title.part1+" "+response.data.data.page_title.part2;
            setContentPara('Discover a complete guide to where to '+response.data.topics+' in '+citynames1+'. Find curated reviews & recommendations.');
            setNavlist(<NavBar nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></NavBar>);
            setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></Footer>);
            setLoader(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const fetchPages = async (city_id,sub_category_id,inner_category_id, page_number) => {    
        try { 
            setLoader(true);       
            setData(null);
            const response = await axios.post('https://broadwayoriginals.com/apis/index.php', {videos: "", subcategory: sub_category_id, innercategory: inner_category_id, page: page_number});
            setData(response.data.data.page_data);
            setAllCities(response.data.allcities);
            const citynames1= '';
            setPageTitle1(response.data.data.page_title.part1);
            setPageTitle2(response.data.data.page_title.part2);
            document.title = "Broadway Originals | "+response.data.data.page_title.part1+" "+response.data.data.page_title.part2;
            setPageMetaTags(response.data.data.meta_tags);
            setContentPara('Discover a complete guide to where to '+response.data.topics+' in '+citynames1+'. Find curated reviews & recommendations.');
            setNavlist(<NavBar nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></NavBar>);
            setPageFooter(<Footer nav_list={JSON.stringify(response.data.navlist)} socailmedia={JSON.stringify(response.data.socailmedia)}></Footer>);
            setLoader(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        if(selectsubcategory != null && selectsubcategory != undefined){
            fetchSubCategoryUrlPageData(selectsubcategory);
        }else{
            fetchPageData();
        }
        
    }, []);
    return(
        <>
            <Container fluid className='top-header'>
            {loaderstate === true && 
                <Row >
                    <Col className='overlay'>
                        <Image src={loader} alt="Loader" className="loader-img" />
                    </Col>
                </Row>}
                <MetaTags tags={pagemetatags} />
            {navlist} 
        </Container>
        <Container>       
            <Row>
                <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12}>
                {pagetitle1!=null && pagetitle1!="" && pagetitle2!=null && pagetitle2!="" &&
                    <h2><span className='view-page-header'>{pagetitle1} </span><span className='view-page-header-span'> {pagetitle2}</span></h2>
                }
                </Col>
            </Row>
            <Row className='mb-3'>
              {contentpara!='' &&
                <Col className='view-page-content'>
                    <p>{contentpara}</p>
                </Col>
                }
            </Row>
            {data!=null && data!="" && 
                <Row>
                {data.map((item1) =>  
                    <>
                        {item1.posts_count > 0 && 
                            <>
                             <Row className='mb-2'>
                                 <Col xxxl={6} xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} xxs={12}>  
                                    {item1.total_pages == undefined &&
                                        <h5><span className="view-page-sub-header">Explore </span><span className="view-page-sub-header-span">{item1.name}</span></h5>
                                    }
                                 </Col>
                                 <Col xxxl={6} xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} xxs={12} className="more-link">
                                    {item1.total_pages == undefined &&
                                    <div className='desktop-view-stack'>                                      
                                        {(item1.id > 0) && (item1.sub_category_id==null || item1.sub_category_id == "" || item1.sub_category_id == 0) &&
                                        <Badge bg="light" text="dark" className="city-btn" style={{borderRadius: "27px", fontWeight: 100, fontSize: "15px"}} onClick={() => fetchSubCategoryPageData(item1.id)}><span style={{color: "#00a8aa"}}>Explore More <FontAwesomeIcon icon={faChevronRight} style={{fontSize: "15px"}} /></span></Badge>  
                                        }
                                        {(item1.id > 0) && (item1.sub_category_id!=null && item1.sub_category_id != "" && item1.sub_category_id > 0) && 
                                        <Badge bg="light" text="dark" className="city-btn" style={{borderRadius: "27px", fontWeight: 100, fontSize: "15px"}} onClick={() => fetchInnerCategoryPageData(item1.sub_category_id, item1.id)}><span style={{color: "#00a8aa"}}>Explore More <FontAwesomeIcon icon={faChevronRight} style={{fontSize: "15px"}} /></span></Badge>  
                                        }
                                    </div>
                                    }
                                        
                                 </Col>
                             </Row>
                             <Row className='mb-2'>
                                 {item1.posts.map((post) =>   
                                     <Col xxxl={3} xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} xxs={12} className="mb-3 mobile-pad" >
                                         <Card style={{padding: "20px", borderRadius: "10px", border: "none"}} className="h-100">
                                             <Card.Img variant="top" src={"https://broadwayoriginals.com/"+post.post_img} />
                                             <Card.Body style={{padding: "0px", marginTop: "20px" }}>
                                                 <Card.Title style={{padding: "0px !important"}}><Nav.Link as={NavLink} to={"/"+post.custom_url} className="right-float"><h6  className="card-row-title">{post.post_title}</h6></Nav.Link></Card.Title>
                                                 <span style={{paddingRight: "10px"}}><FontAwesomeIcon icon={faEye} /></span> <span>{post.post_views == "" ? 0: post.post_views}</span>
                                             </Card.Body>
                                         </Card> 
                                     </Col>
                                 )}
                             </Row>
                             <Row>
                                <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12} className="more-link">
                                    {item1.total_pages == undefined &&
                                    <div className='mobile-view-stack'>
                                        {(item1.id > 0) && (item1.sub_category_id==null || item1.sub_category_id == "" || item1.sub_category_id == 0) &&
                                        <Badge bg="light" text="dark" className="city-btn" style={{borderRadius: "27px", fontWeight: 100, fontSize: "15px"}} onClick={() => fetchSubCategoryPageData(item1.id)}><span style={{color: "#00a8aa"}}>Explore More <FontAwesomeIcon icon={faChevronRight} style={{fontSize: "15px"}} /></span></Badge>  
                                        }                                                                      
                                        {(item1.id > 0)  && (item1.sub_category_id!=null && item1.sub_category_id != "" && item1.sub_category_id > 0) && 
                                        <Badge bg="light" text="dark" className="city-btn" style={{borderRadius: "27px", fontWeight: 100, fontSize: "15px"}} onClick={() => fetchInnerCategoryPageData(item1.sub_category_id, item1.id)}><span style={{color: "#00a8aa"}}>Explore More <FontAwesomeIcon icon={faChevronRight} style={{fontSize: "15px"}} /></span></Badge>  
                                        }
                                    </div>
                                    }
                                        
                                 </Col>
                             </Row>
                             <Row>
                                <Col>
                                {item1.total_pages !== undefined && item1.total_pages > 1 &&
                                    <PaginationBlock  total_pages={item1.total_pages} active_page={item1.active_page} city_id="" sub_category_id={item1.sub_category_id} inner_category_id={item1.id} fetchPages={fetchPages}></PaginationBlock>                                    
                                }
                                </Col>
                             </Row>
                            </>
                        }
                    </>
                   
                )}     
                </Row>
            }
        </Container>
        {pagefooter}
        </>
    );
}


export default Videos;